import React from 'react';
import { useNavigate } from 'react-router-dom';
import BSAFELAB from '../images/BSAFE LAB.png'
import LogoUSC from '../images/USC.png'
import LogoIAIP from '../images/IAIP LOGO - low quality.jpg'
import Donna from '../images/Donna Youngs.jpg'
import Maria from '../images/Maria.jpg'
import John from '../images/John Synnott.jpg'
import Ramón from '../images/Ramon Arce.jpg'
import Paulo from '../images/Paulo-Marques.jpg'
import Dolores from '../images/Dolores Seijo.jpg'
import Francisca from '../images/Francisca Fariña.JPG'
import Manuel from '../images/Manuel Vilariño.jpg'
import Mercedes from '../images/Mercedes_Novo.jpeg'
import Telma from '../images/Telma Almeida.jpg'
 

export default function Organizers() {

  const navigate = useNavigate();

const handleClick1 = () => {
 
   navigate('/profil_dona');
    
};

const handleClick2 = () => {

  navigate('/Dr_John_Synnott');
};
const handleClick3 = () => {

  navigate('/Dr_Paulo_Barbosa_Marques');
  
};
const handleClick4 = () => {

  navigate('/Professor_Maria_Ioannou');
  
};
const handleClick5 = () => {
 
  navigate('/Professor_Ramón_Arce_Fernández');  
};

const handleClick6 = () => {
 
  navigate('/Professor_Mercedes_Novo');  
};
const handleClick7 = () => {
 
  navigate('/Professor_Manuel_Vilariño');  
};
const handleClick8 = () => {
 
  navigate('/Professor_dolores_seijo');  
};
const handleClick9 = () => {
 
  navigate('/ProfessorFranciscaFariña');  
};
const handleClick10 = () => {
 
  navigate('/DrTelmasousaAlmeida');  
};
  return (
    <div className="mt-16 p-4">
      {/* Organising institution */}
    {/*   <div className="bg-gray-50  my-8 p-8 shadow-lg rounded-md mt-8">
        <h2 className="text-3xl md:text-4xl  font-bold mb-2">Organising institution</h2>
        <p className='text-xl my-2 font-bold'>
          The International Academy of Investigative Psychology
        </p >
        <p className=' text-justify'>
          The International Academy for Investigative Psychology is the leading professional academy for  researchers and practitioners seeking to apply social science to the investigation and legal process.
        </p>
        <img src={LogoIAIP} alt="LogoIAIP" className=' mt-8' />
      </div> */}
      {/* Supporting Organisations */}
      {/* <div className="bg-gray-50 p-6 shadow-lg rounded-md mt-8">
        <h2 className="text-3xl md:text-4xl font-bold mb-2">Supporting Organisations</h2>
        <div className="flex items-center space-x-4 mb-2">
        </div>
        <p className='flex space-x-8'>
          <a href="http://www.bsafe-lab.org/" target='blank' className='my-9'>
            <img src={BSAFELAB} alt="BSAFE LAB logo" className=' ' />
          </a>
          <a href="https://www.usc.gal/en" target='blank' className='flex '>
            <img src={LogoUSC} alt="USC logo" className='w-96' />
          </a>
        </p>
      </div> */}
      {/* Organising Committee */}
      <div className="bg-gray-50 p-6 shadow-lg rounded-md mt-8">
  <h2 className="text-3xl md:text-4xl mt-4 font-bold mb-2">Scientific Committee</h2>
  <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4">
    <div className="text-center">
      <img
        /* onClick={handleClick1} */
        src={Donna}
        alt="Donna"
        className="w-24 h-24 mx-auto rounded-full mb-4 transition-transform transform hover:scale-110 cursor-pointer"
      />
      <p className="text-lg font-bold"> Professor Donna Youngs</p>
    </div>

    <div className="text-center">
      <img
       /*  onClick={handleClick4} */
        src={Maria}
        alt="Maria"
        className="w-24 h-24 mx-auto rounded-full mb-4 transition-transform transform hover:scale-110 cursor-pointer"
      />
      <p className="text-lg font-bold">Professor Maria Ioannou</p>
    </div>

    <div className="text-center">
      <img
       /*  onClick={handleClick2} */
        src={John}
        alt="John"
        className="w-24 h-24 mx-auto rounded-full mb-4 transition-transform transform hover:scale-110 cursor-pointer"
      />
      <p className="text-lg font-bold">Dr. John Synnott</p>
    </div>

    <div className="text-center">
      <img
        /* onClick={handleClick5} */
        src={Ramón}
        alt="Ramón"
        className="w-24 h-24 mx-auto rounded-full mb-4 transition-transform transform hover:scale-110 cursor-pointer"
      />
      <p className="text-lg font-bold">Professor Ramón Arce Fernández</p>
    </div>

    <div className="text-center">
      <img
       /*  onClick={handleClick3} */
         src={Paulo} 
        alt="Paulo"
        className="w-24 h-24 mx-auto rounded-full mb-4 transition-transform transform hover:scale-110 cursor-pointer"
      />
      <p className="text-lg font-bold">Dr. Paulo Barbosa Marques</p>
    </div>
    {/* novos organizers */}
    <div className="text-center">
      <img
        /* onClick={handleClick6} */
         src={Mercedes} 
        alt="Mercedes"
        className="w-24 h-24 mx-auto rounded-full mb-4 transition-transform transform hover:scale-110 cursor-pointer"
      />
      <p className="text-lg font-bold">Professor Mercedes Novo</p>
    </div>
    <div className="text-center">
      <img
       /*  onClick={handleClick7} */
         src={Manuel} 
        alt="Manuel"
        className="w-24 h-24 mx-auto rounded-full mb-4 transition-transform transform hover:scale-110 cursor-pointer"
      />
      <p className="text-lg font-bold">Professor Manuel Vilariño</p>
    </div>
    <div className="text-center">
      <img
        /* onClick={handleClick9} */
         src={Francisca} 
        alt="Francisca"
        className="w-24 h-24 mx-auto rounded-full mb-4 transition-transform transform hover:scale-110 cursor-pointer"
      />
      <p className="text-lg font-bold">Professor Francisca Fariña</p>
    </div>
    <div className="text-center">
      <img
       /*  onClick={handleClick8} */
         src={Dolores} 
        alt="Dolores"
        className="w-24 h-24 mx-auto rounded-full mb-4 transition-transform transform hover:scale-110 cursor-pointer"
      />
      <p className="text-lg font-bold">Professor Dolores Seijo</p>
    </div>
    <div className="text-center">
      <img
        /* onClick={handleClick10} */
         src={Telma} 
        alt="Telma"
        className="w-24 h-24 mx-auto rounded-full mb-4 transition-transform transform hover:scale-110 cursor-pointer"
      />
      <p className="text-lg font-bold">Dr. Telma Sousa Almeida</p>
    </div>
  </div>
</div>


    </div>

  );
}
