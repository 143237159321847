import React, { useState, } from 'react';
import { initializeApp } from 'firebase/app';
import { getDatabase, ref, push } from 'firebase/database';
import emailjs from '@emailjs/browser';
import { FaFacebook, FaLinkedin, } from 'react-icons/fa';
import { FaXTwitter } from "react-icons/fa6";
import firebaseConfig from '../firebaseConfig';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FaTelegramPlane } from 'react-icons/fa'; 

const firebaseApp = initializeApp(firebaseConfig);
const database = getDatabase(firebaseApp);

export default function ContactForm() {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    subject: '',
    message: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    toast.success('Informação enviada com sucesso!', { autoClose: 3000 });

    const emailjsParams = {
      serviceId: 'service_ptyzt19',
      templateId: 'template_spd8mbl',
      publicKey: 'OqSyF24145VrRotBk',
    };

    const emailData = {
      name: formData.name,
      email: formData.email,
      subject: formData.subject,
      message: formData.message,
    };

    // Limpar o formulário após o envio
    setFormData({
      name: '',
      email: '',
      subject: '',
      message: '',
    });

    try {
      await emailjs.send(
        emailjsParams.serviceId,
        emailjsParams.templateId,
        emailData,
        emailjsParams.publicKey
      );

      // Envie dados para o Firebase Realtime Database
      try {
        push(ref(database, 'Contact'), emailData);
      } catch (error) {
        // Exibir notificação de erro se necessário 
        toast.error('Erro ao enviar dados para o Firebase. Tente novamente.');
      }
    } catch (error) {
      console.error('Erro ao enviar e-mail:', error);
    }
  };

  return (
    <div className="bg-gray-100 p-8 mt-20 md:p-16">
      <h2 className="text-3xl md:text-4xl font-bold mb-6 text-center text-gray-800">
        Contact Us
      </h2>
      <div className="flex justify-center space-x-4 mb-8">
        {/* Facebook */}
        <div className="text-center">
          <a href="https://www.facebook.com/IAIP2024?sfnsn=wa" target="_blank" rel="noopener noreferrer">
            <div className="bg-blue-500 p-4 rounded-full hover:bg-blue-600 transition duration-300">
              <FaFacebook size={35} className="text-white" />
            </div>
          </a>
          <p className="text-gray-700 mt-2">Facebook</p>
        </div>

        {/* LinkedIn */}
        <div className="text-center">
          <a href="https://www.linkedin.com/in/iaip-conference-69a89b2a5" target="_blank" rel="noopener noreferrer">
            <div className="bg-blue-500 p-4 rounded-full hover:bg-blue-600 transition duration-300">
              <FaLinkedin size={35} className="text-white" />
            </div>
          </a>
          <p className="text-gray-700 mt-2">LinkedIn</p>
        </div>

        {/* Twitter */}
        <div className="text-center">
          <a href="https://twitter.com/IAIP19" target="_blank" rel="noopener noreferrer">
            <div className="bg-blue-500 p-4 rounded-full hover:bg-blue-600 transition duration-300">
              <FaXTwitter size={35} className="text-white" />
            </div>
          </a>
          <p className="text-gray-700 mt-2">Twitter</p>
        </div>
      </div>

      <form className="max-w-md mx-auto bg-white p-8 rounded-md shadow-md" onSubmit={handleSubmit}>
        <div className="mb-4">
          <label htmlFor="name" className="block text-sm font-medium text-gray-700">
            Name <span className="text-red-500">*</span>
          </label>
          <input
            type="text"
            id="name"
            name="name"
            value={formData.name}
            onChange={handleChange}
            required
            className="mt-1 p-2 border rounded-md w-full focus:outline-none focus:ring focus:border-blue-300"
          />
        </div>

        <div className="mb-4">
          <label htmlFor="email" className="block text-sm font-medium text-gray-700">
            Email <span className="text-red-500">*</span>
          </label>
          <input
            type="email"
            id="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            required
            className="mt-1 p-2 border rounded-md w-full focus:outline-none focus:ring focus:border-blue-300"
          />
        </div>

        <div className="mb-4">
          <label htmlFor="subject" className="block text-sm font-medium text-gray-700">
            Subject <span className="text-red-500">*</span>
          </label>
          <input
            type="text"
            id="subject"
            name="subject"
            required
            value={formData.subject}
            onChange={handleChange}
            className="mt-1 p-2 border rounded-md w-full focus:outline-none focus:ring focus:border-blue-300"
          />
        </div>

        <div className="mb-4">
          <label htmlFor="message" className="block text-sm font-medium text-gray-700">
            Message <span className="text-red-500">*</span>
          </label>
          <textarea
            id="message"
            name="message"
            value={formData.message}
            onChange={handleChange}
            rows="4"
            required
            className="mt-1 p-2 border rounded-md w-full focus:outline-none focus:ring focus:border-blue-300"
          ></textarea>
        </div>

        <div className="mb-4 flex">
          <button
            type="submit"
            className="bg-gradient-to-r from-blue-500 to-purple-500 text-white px-8 py-3 rounded-full flex items-center justify-center w-full max-w-2xl hover:bg-blue-600 transition duration-300"
          >
            <span className="mr-2">Submit</span>
            <FaTelegramPlane size={20} /> {/* Use o ícone do Telegram */}
          </button>
        </div>
      </form>

      <ToastContainer />

      <p className="text-gray-700 mt-6 text-center mb-8">
        For any enquiries, please contact us at:{' '}
        <a href="mailto:iaipspain2024@gmail.com" className="text-blue-500 hover:underline">
          iaipspain2024@gmail.com
        </a>
      </p>
    </div>
  );
}
