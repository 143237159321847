import React from 'react';
import { useNavigate } from 'react-router-dom';

export default function Registration ()  {
  const navigate = useNavigate();
  const handleClick = () => {
 
    navigate('/register');  
  };

  return(
    <div className="mt-16 p-8 container mx-auto bg-white shadow-lg rounded-md">
    <div className="text-center mb-8">
      <h1 className="text-4xl font-bold text-gray-800">Conference Registration</h1>
      <p className="text-lg text-gray-600 text-justify">
      To confirm your participation in the 19th Conference of the International Academy of Investigative  Psychology, please register by the registration deadline. 
      Register early to take advantage of Early Bird  Registration rates.If you have any questions about registration, please contact us at:  
        <a href="mailto:iaipspain2024@gmail.com" className="text-blue-500"> iaipspain2024@gmail.com</a>
      </p>
    </div>
    <i className="text-lg text-gray-600 text-justify">If you want to present a paper you need to submit an abstract first via the call for papers page.</i>
    <div className="mb-8">
      <h2 className="text-2xl font-bold mb-4 text-gray-800 mt-3">Conference Registration Fees</h2>
      <table className="w-full border border-collapse">
        <thead>
          <tr className="border-b bg-gray-200">
            <th className="py-2 px-4 border-r">Type</th>
            <th className="py-2 px-4 border-r">Early (until March 31, 2024)</th>
            <th className="py-2 px-4">Regular (After April, 2024)</th>
          </tr>
        </thead>
        <tbody>
          <tr className="border-b">
            <td className="py-2 px-4 text-center border-r">Professional</td>
            <td className="py-2 px-4 text-center border-r">250 €</td>
            <td className="py-2 px-4 text-center">300 €</td>
          </tr>
          <tr className="border-b">
            <td className="py-2 px-4 text-center border-r">Student</td>
            <td className="py-2 px-4 text-center border-r">150 €</td>
            <td className="py-2 px-4 text-center">200 €</td>
          </tr>
        </tbody>
      </table>
    </div>

    <div className="mb-8">
      <h2 className="text-2xl font-bold mb-4 text-gray-800">Workshop Fees (per workshop)</h2>
      <table className="w-full border border-collapse">
        <thead>
          <tr className="border-b bg-gray-200">
            <th className="py-2 px-4 border-r">Type</th>
            <th className="py-2 px-4 border-r">Early (until March 31, 2024)</th>
            <th className="py-2 px-4">Regular (After April, 2024)</th>
          </tr>
        </thead>
        <tbody>
          <tr className="border-b">
            <td className="py-2 px-4 text-center border-r">Professional</td>
            <td className="py-2 px-4 text-center border-r">50 €</td>
            <td className="py-2 px-4 text-center">60 €</td>
          </tr>
          <tr className="border-b">
            <td className="py-2 px-4 text-center border-r">Student</td>
            <td className="py-2 px-4 text-center border-r">25 €</td>
            <td className="py-2 px-4 text-center">35 €</td>
          </tr>
        </tbody>
      </table>
    </div>

    <div className="mb-8">
      <h2 className="text-2xl font-bold mb-4 text-gray-800">Registration Benefits</h2>
      <ul className="list-disc pl-6 text-gray-600">
        <li>Admission to the Welcome Reception and side events</li>
        <li>Inclusion of your presentation in the Conference Programme</li>
        <li>A printed copy of the Conference Guide</li>
        <li>A Certificate of Presentation</li>
        <li>A print-quality PDF of the Conference Programme</li>
        <li>Admission to all sessions, including the Plenary and Featured Speaker sessions and sessions in parallel conferences</li>
        <li>Refreshments and light snacks during morning and afternoon coffee breaks</li>
        <li>Inclusion of your paper in the online Conference Proceedings</li>
        <li>Possibility to have your research published in the special issues in the Journal of Investigative Psychology and Offender Profiling</li>
      </ul>
    </div>

    <div className="text-center">
      <button 
      className="bg-gradient-to-r from-blue-500 to-purple-500 text-white font-bold py-3 px-6 rounded-full"
      onClick={handleClick}
      >
        
        REGISTER NOW
      </button>
    </div>
  </div>
  )
  
}


 

