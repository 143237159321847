import React from 'react'
import { SlActionUndo } from "react-icons/sl";
import { useNavigate } from 'react-router-dom';

export default function Conferencia_themes() {
  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate(-1);
  };

  return (
    <>
     <div className="flex items-center justify-start mb-8 md:mb-10 mt-20 md:mt-20 p-6 md:p-8">
        <button
          className="flex items-center justify-center text-white bg-gradient-to-r from-blue-500 to-purple-500 px-4 py-2 focus:outline-none border  rounded-md transition duration-300"
          onClick={handleGoBack}
        >
          <SlActionUndo  className="text-xl" />
          <span className="ml-2">Voltar</span>
        </button>
      </div>

      <div className=" mt-0 p-8 md:p-16">
        <h2 className="text-3xl md:text-4xl font-bold mb-6 text-center text-gray-800">
          Conference Themes
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {/* Tema 1 */}
          <div className="bg-white p-6 rounded-md shadow-md">
            <h3 className="text-lg font-bold mb-4 text-blue-500">Crime analysis</h3>
            <p className="text-gray-700">
              Exploring advances or not analytical approaches in crime analysis
            </p>
          </div>

          {/* Tema 2 */}
          <div className="bg-white p-6 rounded-md shadow-md">
            <h3 className="text-lg font-bold mb-4 text-blue-500">Geographic profiling </h3>
            <p className="text-gray-700">
              Insights on the process of using geography and the analysis of space and place in understanding and combating criminal behaviour
            </p>
          </div>

          {/* Tema 3 */}
          <div className="bg-white p-6 rounded-md shadow-md">
            <h3 className="text-lg font-bold mb-4 text-blue-500">Investigative Interviewing</h3>
            <p className="text-gray-700">
              Research that advances on our approach to interviewing victims, suspects and at-risk groups.
            </p>
          </div>

          {/* Tema 4 */}
          <div className="bg-white p-6 rounded-md shadow-md">
            <h3 className="text-lg font-bold mb-4 text-blue-500">Violent crime</h3>
            <p className="text-gray-700">
              New research is welcomed that helps to unpack the issues of violent crime with a specific focus on knife crime gangs and youth violence
            </p>
          </div>

          {/* Tema 5 */}
          <div className="bg-white p-6 rounded-md shadow-md">
            <h3 className="text-lg font-bold mb-4 text-blue-500">Terrorism and Radicalisation</h3>
            <p className="text-gray-700">
              The latest advances in terrorism research with a specific emphasis sought on understanding far right extremism and online radicalisation
            </p>
          </div>

          {/* Tema 6 */}
          <div className="bg-white p-6 rounded-md shadow-md">
            <h3 className="text-lg font-bold mb-4 text-blue-500">Domestic violence</h3>
            <p className="text-gray-700">
              We are interested in accepting papers that advances psychology understanding of coercive control as well as domestic violence risk assessment
            </p>
          </div>


          {/* Tema 8 */}
          <div className="bg-white p-6 rounded-md shadow-md">
            <h3 className="text-lg font-bold mb-4 text-blue-500">Sexual offending</h3>
            <p className="text-gray-700">
              Papers are sought that advance our understanding of sexual offending in general but also understanding cognitive thinking styles of those who offend both non-contact and contact offenders
            </p>
          </div>

          {/* Tema 9 */}
          <div className="bg-white p-6 rounded-md shadow-md">
            <h3 className="text-lg font-bold mb-4 text-blue-500">Courtroom Psychology</h3>
            <p className="text-gray-700">
              Papers that showcase the role of psychology in the judicial process are welcomed. Specially the role of expert witness, psychological assessments and jury decision making.
            </p>
          </div>

          {/* Tema 10 */}
          <div className="bg-white p-6 rounded-md shadow-md">
            <h3 className="text-lg font-bold mb-4 text-blue-500">Eyewitness testimony </h3>
            <p className="text-gray-700">
              Papers that advance on the existing research in this area are welcomed specifically the emergence of online witness behaviour (e.g, disappearing messages on social media, etc)
            </p>
          </div>

          {/* Tema 11 */}
          <div className="bg-white p-6 rounded-md shadow-md">
            <h3 className="text-lg font-bold mb-4 text-blue-500">Predictive Policing</h3>
            <p className="text-gray-700">
              Any research into predictive policing and the role of analytical decision making in police practice are sought

            </p>
          </div>

          {/* Tema 12 */}
          <div className="bg-white p-6 rounded-md shadow-md">
            <h3 className="text-lg font-bold mb-4 text-blue-500">Criminal Networks and Organised Crime </h3>
            <p className="text-gray-700">
              We welcome papers that provide a current understanding of organised crime networks and would be particularly interested in work around international law enforcement cooperation related to the Encrochat hack and what we learned from how these organised crimes groups communicate with each other
            </p>
          </div>

          {/* Tema 13 */}
          <div className="bg-white p-6 rounded-md shadow-md">
            <h3 className="text-lg font-bold mb-4 text-blue-500">Cybercrime</h3>
            <p className="text-gray-700">
              Any work that advances our understanding of cyber related crime including but not limited to cyberbullying and abuse are welcomed.

            </p>
          </div>

          {/* Tema 14 */}
          <div className="bg-white p-6 rounded-md shadow-md">
            <h3 className="text-lg font-bold mb-4 text-blue-500">Police decision making</h3>
            <p className="text-gray-700">
              We welcome papers that explore the role of police decision making in complex investigations and critical incidents

            </p>
          </div>
          <div className="bg-white p-6 rounded-md shadow-md">
            <h3 className="text-lg font-bold mb-4 text-blue-500">Volume crime</h3>
            <p className="text-gray-700">
            We welcome papers concerned with tackling volume crime with specific focus on street robbery of luxary items e.g mobile phones and watches as well is the role of e scooters in street robbery  
            </p>
          </div>
          <div className="bg-white p-6 rounded-md shadow-md">
            <h3 className="text-lg font-bold mb-4 text-blue-500">Offender profiling</h3>
            <p className="text-gray-700">
            Papers are welcomed on any topics that advances our understanding of offender profiling 
            </p>
          </div>
          <div className="bg-white p-6 rounded-md shadow-md">
            <h3 className="text-lg font-bold mb-4 text-blue-500">Crime linkage</h3>
            <p className="text-gray-700">
            Papers that showcase work on linking crimes are encouraged and we particularly welcome studies that have a global reach in terms of data sources drawn on.

            </p>
          </div>
          <div className="bg-white p-6 rounded-md shadow-md">
            <h3 className="text-lg font-bold mb-4 text-blue-500">Risk assessments</h3>
            <p className="text-gray-700">
            Any research that advances our understanding of the assessment of risk are welcome. We would like to encourage any risk assessment work on Child Criminal Exploitation, Sexual Exploitation and Domestic violence risk assessments from both victim and offender perspectives. Finally, any work that takes cultural context into account or tests and validation risk assessments in new environments are sought. 
            </p>
          </div>


          {/* Continue para outros temas conforme necessário */}


        </div>
      </div>
      <div className="flex justify-center mb-8">
        <button
          onClick={handleGoBack}
          className="bg-gradient-to-r from-blue-500 to-purple-500 text-white px-8 py-3 rounded-full hover:bg-blue-600 transition duration-300"
        >
          Submit Abstract Here
        </button>
      </div>
    </>

  )
}
