import React, { useState, useEffect } from 'react';




const KeynoteSpeakersTab = () => {
  const [selectedSpeaker, setSelectedSpeaker] = useState(null);
  const [activeTab, setActiveTab] = useState('keynote');
  const isMobile = window.innerWidth <= 768; // Ajuste para considerar dispositivos móveis



  const speakers = [
    {
      name: 'Dr Joanne Hands',
      title: 'President of the Middle East Psychological Association (MEPA)',
      bio: 'Dr. Joanne Hands is the president of the Middle East Psychological Association (MEPA) and a member of the American Psychological Association (APA), American Counseling Association (ACA), and Venezuelan Federation of Psychologists (FPV). She is a Psychologist with a License as a Marriage and Family Therapist (LMFT) and Professional Counselor (LPC), in the state of Louisiana, USA. In addition, she is a Certified Counselor by the American National Board of Certified Counselors (NCC) as well as Certified in Myers Briggs Type Indicator (MBTI) personality inventory. Dr. Joanne was honored with an APA Presidential Citation for her visionary leadership, tireless promotion of the important role of psychology for all, and elevation of the profession in the Middle East region.',
      imageSrc: require('../component/images/Joanne Hands.jpg'),
    },
    {
      name: 'Professor David Canter',
      title: 'Emeritus Professor of Psychology, University of Liverpool',
      bio: 'Over the last 20 years Professor Canter has been prolific in mapping out the discipline of Investigative Psychology, opening up thinking on the psychology of criminal actions, and the ways in which this can inform police investigations and the courts.Professor Canter is frequently asked to contribute to strategic policing issues at national and international levels. He has provided expert evidence in diverse legal cases of international significance.David’s award-winning (Anthony Award; Crime Writer’s Association Golden Dagger Award) account of the development of the science behind “offender profiling” and key cases using “geographical offender profiling” can be found in his popular books Criminal Shadows as well as Mapping Murder.David Canter’s commitment to the systematic, scientific analysis of human behaviour, which recently earned him one of only 26 prestigious BPS Honorary Fellowships ever awarded across the discipline, emerged from his work on how people act in disasters and emergency situations, from the Kings Cross fire to the Bradford City football ground disaster to the evacuation of the World Trade Center on 9/11.Earlier in his career, Professor Canter played a crucial role in establishing another sub-discipline: Environmental Psychology. He continues to lecture around the world on developments in this field.',
      imageSrc: require('../component/images/David Canter.jpg'),
    },
    {
      name: 'Professor David Walsh',
      title: 'Professor in Criminal Investigation, De Montfort University',
      bio: 'Dave Walsh, Ph.D., is Professor of Criminal Investigation in the School of Law at De Montfort University, Leicester, UK. His PhD in 2010 concerned the interviewing of fraud suspects. He works with many academics and law enforcement agencies around the world, advising on their investigation and interview methods. He has published over 80 peer-reviewed journal articles, books and book chapters on the subject of criminal investigation, particularly in the area of investigative interviewing of victims, witnesses and suspects. He has a world-wide reputation as one of the leading scholars in this subject area. He is the lead editor of a forthcoming volume in the Summer of 2024 entitled: The International Handbook of Investigative Interviewing and Interrogation (Routledge). The Handbook is a sister volume to two earlier ones, where he was again lead editor; International Developments and Practice in Investigative Interviewing and Interrogation (Volumes 1 and 2). Together the three volumes cover contribution from authors in 70 countries  He is currently leading an international group of 100+ scholars and practitioner from over 40 countries on a four year project titled, ImpleMendez,   in efforts to strengthen research networks, further develop academic collaborations and help implement the Mendez Principles around the world. ',
      imageSrc: require('../component/images/Dave-Walsh.jpg'),
    },
    {
      name: 'Professor Maria Ioannou',
      title: 'Professor of Investigative and Forensic Psychology, University of Huddersfield',
      bio: 'Professor Maria Ioannou  (BSc, MSc, PhD, C.Psychol, CSci, AFBPsS, FHEA, CMgr, MCMI), is a Professor of Investigative and Forensic Psychology, Director for two postgraduate courses: MSc Investigative Psychology and MSc Security Science and ex-Director of the Secure Societies Institute at the University of Huddersfield, UK. She is also a Chartered Forensic Psychologist (BPS), Health and Care Professions Council (HCPC) Registered Psychologist, Chartered Scientist (BPS), Chartered Manager (Chartered Management Institute), Fellow of the Higher Education Academy, an Associate Fellow of the British Psychological Society and board member of the Institute on Crime and Criminal Justice Policy, European Public Law Organisation (EPLO). Prof. Dr. Ioannou has considerable experience in exploring offender and victim profiles and has been involved in the assessment and development of intervention programmes for reducing/preventing crime for a range of different forms of criminality and groups of offenders and ‘at risk’ individuals as well as impact delivery. She has delivered a variety of courses to professionals as well as applied research, case reports and consultancy projects to police, government authorities, intervention agencies and other public bodies and has contributed to various legal cases. Prof. Dr. Ioannou has been invited to train law enforcement and security personnel as well as give lectures and seminars in a number of countries in Europe, Middle East and South East Asia. She has an extensive publication record, media experience across all platforms appearing on CNN and BBC radio and her work has been presented nationally and internationally.',
      imageSrc: require('../component/images/Maria.jpg'),
    },
  /*   {
      name: 'Professor Antonio Andrés-Pueyo', //A espera pelos dados 
      title: 'Professor of Violence Psychology and Criminal Psychology',
      bio: 'Professor of Violence Psychology and Criminal Psychology. Director of the Department of Clinical Psychology and Psychobiology at the University of Barcelona. Director of the Criminal and Forensic Psychology Master. Supervisor and consultor of governmental agencies in Law and Enforcement Services, Penitentiary and Juvenile Justice in Spain and Latinoamerica and private and NGO services. His main research topic is violence risk assessment in professional settings. He has published papers and reports in violence prevention, aggression, impulsivity, individual differences and personality.Director of Master’s Degree in Forensic and Criminal Psychology, UB/IL3',
      imageSrc: require('../images/Andres-Pueyo.jpg'),
    }, */
  ];

  const workshops = [
    {
      name: 'Professor David Walsh',
      title: 'Workshop in Investigative Interviewing (4h)',
      speaker: 'The 4-hour workshop will cover an introduction to some of the practical measures that are key to the undertaking of investigative interviewing models. The measures are rooted in science, consequent to the research that has emerged largely over the last 20 years. The workshop will be participative as well as instructive and thus will be aimed as appealing to practitioners, policy makers and academics (whether established or new to the subject).  Particularly, there is coverage on interview planning, rapport building, questioning strategies, interview structure, and evidence disclosure.',
      imageSrc: require('../component/images/Dave-Walsh.jpg'),
    },
    {
      name: 'Professor David Canter',
      title1: 'Workshop 1:',
      title2: 'Workshop 2:',
      subtitle1: 'An Introduction to Dragnet and Geographical Offender Profiling',
      subtitle2: 'An Introduction to Facet Theory and Smallest Space Analysis',
      speaker1: 'The principal findings on which GOP is based are introduced: propinquity and morphology. How these are combined in the Dragnet software are described. The situations in which Dragnet can be of value in research and practice are reviewed. The limitations on the effectiveness of GOP are considered and the ethical and professional constraints under which it should be used are summarised.',
      speaker2: 'The principles of the Facet approach to research are summarised and how it differs from positivistic empiricism are described. The particular statistical methodology of Smallest Space Analysis (SSA), a variety of multi-dimensional scaling, is presented and its emergence as an aspect of the Facet approach to research is explained. The structures that are discovered in SSA results are considered and the particular power of the Radex structure is presented with a number of crime related examples.',
      imageSrc: require('../component/images/David Canter.jpg'),
    },
  ];


  const conferenceProgram = 'To check out our amazing line-up of keynote speeches, panel sessions, talking posters, applied sessions please click on: ';
  /* const AppliedSessions = 'teste'; */
  const AppliedSessions = [
    {
      name: 'Professor Jodi Quas',
       title: 'Best Practice Approaches to Improving Police Interviewing of Adolescent Victims and Witnesses',
       speaker: 'In this 2-hour workshop, Dr. Jodi Quas will review the state of the science concerning forensic interviewing of adolescent victims and witnesses. In contrast to the large amounts of scientific research and police training that have focused on legal questioning of child victims/witnesses and adult victim/witnesses, far less attention has been devoted to training police on legal questioning of adolescent victims or witnesses. The lack of attention is particularly concerning given that adolescents (i.e., youth ages 12-18) are highly likely to experience or witness crime. For example, adolescents in many countries across Europe, Canada, and the U.S. are the second most common age group to be exposed to violent crimes and as a result be questioned by police about what has happened. Dr. Quas, a leading expert on children’s and adolescents’ eyewitness capabilities, will present new research on interviewing approaches specifically for adolescents. She will begin by discussing the types of criminal activity adolescents are most likely to experience and witness (e.g., interpersonal violence, online and offline exploitation) and the reasons why adolescents are highly reluctant to discuss those experiences with authorities. She will then describe how adolescent reluctance is expressed in interviews with police and interactions with other legal professionals. Finally, she will present new work that is documenting specific interview tactics (e.g., rapport building, question phrasing) that increase adolescents comfort in interviews and willingness to discuss negative personal experiences. Attendees of the workshop will come away with knowledge of evidence-based recommendations regarding best practice questioning approaches for adolescents, a unique and particularly vulnerable population of victims and witnesses.',
       imageSrc: require('../component/images/jodie.jpeg'),
    },
    {
      name: 'Professor Ramón Arce',
       title: 'The Global Evaluation System forensic technique to assess psychological damage',
       speaker: 'Criminal cases against persons are committed ordinarily in private sphere reducing mainly (the estimation is about 85% of cases) the incriminate proofs to victim-witness testimony and the evaluation of the damages to the victim claimant. Nonetheless, the victim testimony about the event and the victim reports about the damages are not sufficient evidence to sentence the accused. As the burden of proof corresponds to accusation, prosecution requires expert reports to bestow probationary value to victim testimony and/or damage to set aside the presumption of innocence principle. To expert psychological reports about psychological damage the following criteria are required: the application of a scientific-based forensic technique, the measurement of psychological damage, the differential diagnosis of malingering (none malingered damage may be classified as real damage), and the establishment of a causal link between the investigated crime and the psychological damage. The structured clinical interviews and clinical diagnosis are not valid for this purpose: have no psychometric properties and do not measure the differential diagnosis of malingering. Thus, we created the forensic clinical interview to measure psychological damage and the differential diagnosis of malingering. This tool was reliable (α = .85 for the measurement of psychological damage and α = .744 for the measurement of malingering) and valid (predictive, convergent and discriminant validity). Then, we designed a multi-method (knowledge and recognition task) and multi-measurement (interview and psychometric) forensic technique, inserted into the Global Evaluation System, that was scientifically tested and validated. In this applied session, the forensic evaluation of psychological damage technique will be developed.',
       imageSrc: require('../component/images/Ramon Arce.jpg'),
    },
    {
      name: 'Secretaría de Estado de Seguridad',
       title: 'More information will be available soon.',
      speaker: 'More information will be available soon.',
       imageSrc: require('../component/images/IAIP LOGO - low quality.jpg'),
    },
  ];


  const handleClick = (index) => {
    setSelectedSpeaker((prevIndex) => (prevIndex === index ? null : index));
  };

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  useEffect(() => {
    const closeBioOnClickOutside = (event) => {
      if (selectedSpeaker !== null) {
        const isOutsideCard = !event.target.closest('.speaker-card');
        if (isOutsideCard) {
          setSelectedSpeaker(null);
        }
      }
    };

    document.addEventListener('click', closeBioOnClickOutside);

    return () => {
      document.removeEventListener('click', closeBioOnClickOutside);
    };
  }, [selectedSpeaker]);

  return (
    <div className="mt-20 text-justify md:p-16">
    <div className="flex flex-wrap justify-center mb-8">
  <button
    className={`mx-2 my-2 px-4 py-2 focus:outline-none ${activeTab === 'keynote' ? 'border-b-2 border-blue-500' : ''}`}
    onClick={() => handleTabChange('keynote')}
  >
    Keynote Speakers
  </button>
  <button
    className={`mx-2 my-2 px-4 py-2 focus:outline-none ${activeTab === 'workshops' ? 'border-b-2 border-blue-500' : ''}`}
    onClick={() => handleTabChange('workshops')}
  >
    Workshops
  </button>
  <button
    className={`mx-2 my-2 px-4 py-2 focus:outline-none ${activeTab === 'program' ? 'border-b-2 border-blue-500' : ''}`}
    onClick={() => handleTabChange('program')}
  >
    Conference Program
  </button>
  <button
    className={`mx-2 my-2 px-4 py-2 focus:outline-none ${activeTab === 'applied' ? 'border-b-2 border-blue-500' : ''}`}
    onClick={() => handleTabChange('applied')}
  >
    Applied Sessions
  </button>
</div>

      {activeTab === 'keynote' && (
        <div className={`grid ${isMobile ? 'grid-cols-1' : 'grid-cols-2 md:grid-cols-3'} gap-4`}>
          {speakers.map((speaker, index) => (
            <div
              key={index}
              onClick={() => handleClick(index)}
              className={`cursor-pointer p-4 border rounded-lg ${selectedSpeaker === index ? 'border-blue-500' : 'border-gray-300'
                } speaker-card`}
            >
              <img
                src={speaker.imageSrc}
                alt={speaker.name}
                className="w-full h-48 object-cover mb-4 rounded-md"
              />
              <p className="text-lg font-bold">{speaker.name}</p>
              <p className="text-gray-600">{speaker.title}</p>
              {selectedSpeaker === index && (
                <div className="mt-4">
                  <h4 className="text-xl font-bold mb-2">Biography</h4>
                  <p className="text-gray-700">{speaker.bio}</p>
                </div>
              )}
            </div>
          ))}
        </div>
      )}

{activeTab === 'workshops' && (
  <div className="flex justify-center items-center">
    <div className={`grid gap-4 ${isMobile ? 'grid-cols-1' : 'md:grid-cols-1 lg:grid-cols-2 xl:grid-cols-3'} mx-auto p-4`}>
      {workshops.map((workshop, index) => (
        <div key={index} className="p-4 border rounded-lg border-gray-300 shadow-lg transform hover:scale-105 transition-transform duration-200">
          <img
            src={workshop.imageSrc}
            alt={workshop.name}
            className="mx-auto mb-4 rounded-full w-32 h-32 lg:w-48 lg:h-48 xl:w-56 xl:h-56 object-cover"
          />
          <p className='mb-4 text-center text-2xl font-bold text-gray-800'>{workshop.name}</p>
          {workshop.title && <p className="text-lg font-bold text-gray-700">{workshop.title}</p>}
          {workshop.speaker && <p className="text-gray-600">{workshop.speaker}</p>}
          {workshop.title1 && (
            <>
              <p className="text-lg font-bold text-gray-700">{workshop.title1}</p>
              <p className="text-gray-600">{workshop.subtitle1}</p>
              <p className="text-gray-600">{workshop.speaker1}</p>
            </>
          )}
          {workshop.title2 && (
            <>
              <p className="text-lg font-bold text-gray-700">{workshop.title2}</p>
              <p className="text-gray-600">{workshop.subtitle2}</p>
              <p className="text-gray-600">{workshop.speaker2}</p>
            </>
          )}
        </div>
      ))}
    </div>
  </div>
)}

      {activeTab === 'program' && (
        <div className="p-4 border rounded-lg border-gray-300">
          <p className="text-lg">{conferenceProgram}  <a href='/PDF/IAIP Leaflet (1) (2).pdf' target="_blank" rel="noopener noreferrer" className="text-blue-500 hover:underline">
          Conference Program
      </a></p>
      <div className='flex justify-center text-center p-4 mt-2'>
              <a href='/PDF/IAIP Book of Abstracts_v4.pdf' target="_blank" className=' md:text-center md:text-2xl font-extrabold text-xl text-blue-500 p-2 hover:text-blue-300 underline cursor-pointer'>19th IAIP Book of Abstracts</a>
      </div>
        </div>
      )}
      
{activeTab === 'applied' && (
  <div className="flex justify-center items-center ">
          <div className={`flex flex-col gap-4 ${isMobile ? 'grid-cols-1' : 'md:grid-cols-1 lg:grid-cols-2 xl:grid-cols-3'} mx-auto p-4`}>
                {AppliedSessions.map((applied, index) => (
       <div key={index} className="p-4 border rounded-lg border-gray-300 shadow-lg transform hover:scale-105 transition-transform duration-200">
       <img
         src={applied.imageSrc}
         alt={applied.name}
         className="mx-auto mb-4 rounded-full w-32 h-32 lg:w-48 lg:h-48 xl:w-56 xl:h-56 object-cover"
       />
       <p className='mb-4 text-center text-2xl font-bold text-gray-800'>{applied.name}</p>
       <p className="text-lg font-bold text-gray-700">{applied.title}</p>
       <p className="text-gray-600">{applied.speaker}</p>
     </div>
    ))}
  </div>
  </div>

)}



    </div>
  );
};

export default KeynoteSpeakersTab;