import React from 'react';
import Mercedes from '../images/Mercedes_Novo.jpeg';

export default function Organizers() {
    return (
        <div className="flex flex-col items-center justify-center min-h-screen mt-46 p-4">
            <div className="text-center">
                <img
                    src={Mercedes}
                    alt="Mercedes"
                    className="w-full max-w-xs mx-auto rounded-full mb-4 transition-transform transform hover:scale-110 cursor-pointer object-cover"
                />
                <p className="text-3xl font-bold">Professor Mercedes Novo</p>
            </div>
        </div>
    );
}
