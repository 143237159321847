import React from 'react';
import { SlActionUndo } from "react-icons/sl";
import { useNavigate } from 'react-router-dom';
import Paulo from '../biography/images/Paulo-Marques.jpg'

export default function Biography_Dr_Paulo_Barbosa_Marques() {
  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate(-1);
  };

  return (
    <>
      <div className="flex items-center justify-start mb-8 md:mb-10 mt-20 md:mt-20 p-6 md:p-8">
        <button
          className="flex items-center justify-center text-white bg-gradient-to-r from-blue-500 to-purple-500 px-4 py-2 focus:outline-none border  rounded-md transition duration-300"
          onClick={handleGoBack}
        >
          <SlActionUndo  className="text-xl" />
          <span className="ml-2">Back</span>
        </button>
      </div>
      <div className="mb-8 md:mb-10 p-6 md:p-8 bg-white rounded-md shadow-md max-w-2xl mx-auto">
        <h2 className="text-3xl md:text-4xl text-center font-semibold mb-4 md:mb-6 text-slate-900">
          Dr. Paulo Barbosa Marques
        </h2>
        <div className="flex flex-col items-center space-y-4">
          <img
            src={Paulo}
            alt="Paulo"
            className="rounded-full h-40 md:h-60 w-40 md:w-60 mb-2 md:mb-4 object-cover"
          />
          <p className="text-sm md:text-base text-gray-800 text-justify">
            Paulo Barbosa Marques is a chartered clinical and forensic psychologist, a researcher, and a law enforcement officer in Portugal. He is a former second Lieutenant at the Portuguese Army Special Operations Force. Paulo began his law enforcement career serving as a frontline police officer in the Polícia de Segurança Pública (PSP) and later as a criminal investigator at the Criminal Investigation Department – Organized Crime Brigade. He is a former Inspector at the Serviço de Estrangeiros e Fronteiras (SEF), the Portuguese Immigration and Borders Service. Currently, Paulo is an Inspector at Polícia Judiciária, the Portuguese Criminal Police.
            Paulo is an associated researcher affiliated to the BSAFE LAB – Law Enforcement, Justice and Public Safety Research and Technology Transfer Laboratory (Universidade da Beira Interior). He is a founding member and a former Secretary-General of the Portuguese Society of Psychiatry and Psychology of Justice (SPPPJ), an International Investigative Interviewing Research Group (iIIRG) Regional Champion for Portugal and an external expert at the Global Initiative Against Transnational Organized Crime (GI-TOC). Paulo regularly presents at conferences to both academics and practitioners and acts as an international consultant in capacity-building initiatives in the fields of law enforcement, immigration and asylum.
            Paulo Barbosa Marques is a member of the Management Committee of the European Cooperation in Science & Technology (COST) action “CA22128 – Establishing Networks to Implement the Principles on Effective Interviewing for Investigations (IMPLEMENDEZ)” and many other projects in the field of investigative interviewing.

          </p>
        </div>
      </div>
    </>
  );
}
