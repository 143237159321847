import React, { useState } from 'react';
import { SlActionUndo } from "react-icons/sl";
import { useNavigate } from 'react-router-dom';

const SubmissionInfo = () => {
  const [submissionType, setSubmissionType] = useState('');
  const navigate = useNavigate();

  const handleSubmissionTypeChange = (event) => {
    setSubmissionType(event.target.value);
  };

  const handleGoBack = () => {
    navigate(-1);
  };

  return (
    <>
     <div className="flex items-center justify-start mb-8 md:mb-10 mt-20 md:mt-20 p-6 md:p-8">
        <button
          className="flex items-center justify-center text-white bg-gradient-to-r from-blue-500 to-purple-500 px-4 py-2 focus:outline-none border  rounded-md transition duration-300"
          onClick={handleGoBack}
        >
          <SlActionUndo  className="text-xl" />
          <span className="ml-2">Back</span>
        </button>
      </div>

      <div className="container mx-auto p-6 bg-white rounded-md shadow-md mt-0 md:mt-0 lg:w-3/4 xl:w-1/2">


        <div className="mb-6">
          <label htmlFor="submissionType" className="block text-2xl font-bold">
          Types of submissions – overview
          </label>
          <select
            id="submissionType"
            onChange={handleSubmissionTypeChange}
            value={submissionType}
            className="mt-1 p-2 border rounded-md w-full focus:outline-none focus:ring focus:border-blue-300"
          >
            <option value="">Select...</option>
            <option value="panel">Panel Session</option>
            <option value="oral">Oral Presentation</option>
            <option value="poster">Talking Poster</option>
          </select>
        </div>

        {submissionType === 'panel' && (
          <div className="mb-6">
            <h3 className="text-2xl font-semibold mb-2">Panel Session</h3>
            <p className="text-gray-700">
              We welcome panels on specific common topics of interest. Each panel should consist of a panel chair and
              three to five colleagues. A panel will contain a maximum of 5 presentations and can last up to 1.5 hours
              in total. The duration of one presentation is 15 minutes maximum. Within each symposium, a minimum of 10
              minutes should be left for final discussions and questions.
            </p>
          </div>
        )}

        {submissionType === 'oral' && (
          <div className="mb-6">
            <h3 className="text-2xl font-semibold mb-2">Oral Presentation</h3>
            <p className="text-gray-700">
              The duration of oral presentations is 15 minutes for the presentation and 5 minutes for discussions and
              questions.
            </p>
          </div>
        )}

        {submissionType === 'poster' && (
          <div className="mb-6">
            <h3 className="text-2xl font-semibold mb-2">Talking Poster</h3>
            <p className="text-gray-700">
              During the talking posters session, the author(s) will have 5 minutes to present their research and 2
              minutes for questions.
            </p>
          </div>
        )}

        <div className="mb-6">
          <h3 className="text-2xl font-semibold mb-2">Content of Submission</h3>
          <ul className="list-disc pl-8 text-gray-700">
            <li className="mb-2">Title of the study</li>
            <li className="mb-2">Author(s) of the study</li>
            <li className="mb-2">Presenting author</li>
            <li className="mb-2">E-mail of the presenting author</li>
            <li className="mb-2">
              Main text of the abstract (max 300 words), which should include the following:
              <ul className="list-disc pl-8">
                <li className="mb-2">Aim of the study</li>
                <li className="mb-2">Methodology</li>
                <li className="mb-2">Results</li>
                <li className="mb-2">Conclusion</li>
              </ul>
            </li>
            <li>3-5 keywords for your abstract</li>
          </ul>
          <p className="mt-4 text-gray-700">
            Do not include tables, graphs, special symbols, or references in the abstract. When submitting the abstract,
            please choose the type of submission (panel session, oral presentation, talking poster).
          </p>
        </div>
      </div>
    </>
  );
};

export default SubmissionInfo;
