import React from 'react';
import { SlActionUndo } from "react-icons/sl";
import { useNavigate } from 'react-router-dom';
import John from '../biography/images/John Synnott.jpg'

export default function Biography_Dr_John_Synnott() {
  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate(-1);
  };

  return (
    <>
       <div className="flex items-center justify-start mb-8 md:mb-10 mt-20 md:mt-20 p-6 md:p-8">
        <button
          className="flex items-center justify-center text-white bg-gradient-to-r from-blue-500 to-purple-500 px-4 py-2 focus:outline-none border  rounded-md transition duration-300"
          onClick={handleGoBack}
        >
          <SlActionUndo  className="text-xl" />
          <span className="ml-2">Back</span>
        </button>
      </div>
      <div className="mb-8 md:mb-10 p-6 md:p-8 bg-white rounded-md shadow-md max-w-2xl mx-auto">
        <h2 className="text-3xl md:text-4xl text-center font-semibold mb-4 md:mb-6 text-slate-900">
          Dr. John Synnott
        </h2>
        <div className="flex flex-col items-center space-y-4">
          <img
            src={John}
            alt="John"
            className="rounded-full h-40 md:h-60 w-40 md:w-60 mb-2 md:mb-4 object-cover"
          />
          <p className="text-sm md:text-base text-gray-800  text-justify">

            Dr. John Synnott BSc, MSc, PhD, C.Psychol, CSci, AFBPsS, FHEA.

            Dr John Synnott is an Associate Professor of Psychology, a Chartered Psychologist (British Psychological Society), Chartered Scientist (BPS), an Associate Fellow of the British Psychology Society and a Fellow of the Higher Education Academy. John has presented research on the Psychology of Crime and Offender Decision Making at conferences around the world. He recently presented a keynote talk in Baghdad, Iraq on the application of Investigative Psychology. Dr Synnott also recently delivered several keynote presentations to the Royal Thai Police in both Bangkok and Chiang Mai Thailand.

            Dr Synnott has provided consultation on the development of a Risk Assessment Tool for Domestic Violence for police force across the UK and overseas. He recently published a special issue as guest editor on the topic of Sexual Violence and Domestic Abuse for the Journal of Investigative Psychology and Offender Profiling. John has extensive experience in the evaluation of intervention programs recently he has completed an evaluation of a prison training program at Shelton Abbey Prison in Ireland. He also completed a Mental Wellbeing project at the same facility that was presented at the British Psychology Societies Division of Forensic Psychology conference in Bristol.

            Dr Synnott is in the process of establishing the UKs first National Police Doctoral College to support police-based PhD projects. This build on an existing program that he started in October 2022 involving doctoral candidates that are drawn from policing partners in the Northwest of England, consisting of the highest operational ranks in UK policing. John directly supervises all of these projects as main supervisor which include studies on Child Criminal Exploitation, Gender and Race in Policing, the Role of Interventions in Combating Exploitation and Womens role in Serious and Organised Crime. In addition to this he also directs a number of projects and supervises a number of other PhDs, on but not limited to the following topics: Polygraph in Police Investigations, Risk Taking Behaviour, Offending and Mental Health, Sexual Assault Assessments, Sexual Offending in Southeast Asia, Human Trafficking in the Middle East, Bullying, Rape Myths, Stalking and Domestic Violence and Abuse in the GCC.

            Dr Synnott has supervised over 60 master’s research projects in the area of Psychology within the Middle East and is supervising and supporting several PhD Candidates from the region,
            who are developing important research in the areas of Trafficking, Domestic/Sexual Violence, Youth Violence, Offender Profiling, Social Media, Public Order, Terrorism and Radicalisation. Dr Synnott has published extensively over the past 10 years and has over 35 peer research papers in high-ranking academic journals.

            John is currently completing a number of funded projects. One example is a piece of work funded by Merseyside Police on the non-consensual distribution of imagery amongst adolescents as well as a funded project examining the nature of Cuckooing offences in the UK. This is in addition to a recently completed piece of work with his colleagues which was a funded rapid evidence assessment for the Home Offices, Independent Inquiry into Child Sexual Abuse and an evaluation of a Domestic Violence Judicial Protocol with Thames Valley Police. John has extensive Media experience having appeared on radio (Newstalk, Capital FM) and television (BBC) and his work has featured in media both nationally and internationally (Washington Post & CNN) as well has being featured in the prestigious journal of Nature. John is the Editor for the newly commissioned Forensic Psychology in the Middle East Series which will be published in 2024.

          </p>
        </div>
      </div>
    </>
  );
}
