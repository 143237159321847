import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Home from '../pages/Home';
import Organizers from '../pages/Organizers';
import Contact from '../pages/Contact';
import Information from '../pages/GeneralInformation';
import Programme from '../pages/IssuesProgramme';
import ScientificCommittee from '../pages/ScientificCommittee';
import Papers from '../pages/CallforPapers';
import Submissions from '../pages/Submission_Guidelines'
import Conferencia from '../component/conferencia themes/Conferencia_themes'
import Dona_Youngs from '../component/biography/biography_Donna _Youngs '
import Dr_John_Synnott from '../component/biography/biography_Dr_John_Synnott '
import Dr_Paulo_Barbosa_Marques from '../component/biography/biography_Dr_Paulo_Barbosa_Marques '
import Professor_Maria_Ioannou from '../component/biography/biography_Professor_Maria_Ioannou '
import Professor_Ramón_Arce_Fernández from '../component/biography/biography_Professor_Ramón_Arce_Fernández '
import Professor_Mercedes_Novo from '../component/biography/byography_Professor_Mercedes_Novo'
import Professor_Manuel_Vilariño from '../component/biography/byography_Professor_Manuel_Vilariño'
import Professor_Dolores_Seijo from '../component/biography/byography_Professor_Dolores_Seijo'
import Professor_Francisca_Fariña from '../component/biography/byography_Professor_Francisca_Fariña'
import DrTELMASOUSAALMEIDA from '../component/biography/byographyDr. TELMASOUSAALMEIDA'
import Secretariat from '../pages/secretariat';
import Registration from '../pages/Registration';
import Register from '../register/Register';
import Time from '../pages/Time';



const AppRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/home" element={<Home />} />
      <Route path="/organizers" element={<Organizers />} />
      <Route path="/information" element={<Information />} />
      <Route path="/programme" element={<Programme />} />
      <Route path="/papers" element={<Papers />} />
      <Route path="/submissions" element={<Submissions />} />
      <Route path="/registration" element={<Registration />} />
      <Route path="/register" element={<Register />} />
      <Route path="/ScientificCommittee" element={<ScientificCommittee />} />
      <Route path="/contact" element={<Contact />} />
      <Route path="/Secretariat" element={<Secretariat />} />
      <Route path="/conferencia" element={<Conferencia />} />
      <Route path="/profil_dona" element={<Dona_Youngs />} />
      <Route path="/Dr_John_Synnott" element={<Dr_John_Synnott />} />
      <Route path="/Dr_Paulo_Barbosa_Marques" element={<Dr_Paulo_Barbosa_Marques />} />
      <Route path="/Professor_Maria_Ioannou" element={<Professor_Maria_Ioannou />} />
      <Route path="/Professor_Ramón_Arce_Fernández" element={<Professor_Ramón_Arce_Fernández />} /> 
      <Route path="/Professor_Mercedes_Novo" element={<Professor_Mercedes_Novo />} /> 
      <Route path="/Professor_Manuel_Vilariño" element={<Professor_Manuel_Vilariño />} /> 
      <Route path="/Professor_dolores_seijo" element={<Professor_Dolores_Seijo  />} /> 
      <Route path="/ProfessorFranciscaFariña" element={<Professor_Francisca_Fariña  />} /> 
      <Route path="/DrTelmasousaAlmeida" element={<DrTELMASOUSAALMEIDA  />} />
      <Route path="/time" element={<Time />} /> 
    </Routes>
  );
};

export default AppRoutes;
