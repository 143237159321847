import React from 'react';
import { SlActionUndo } from "react-icons/sl";
import { useNavigate } from 'react-router-dom';
import Dolores from '../biography/images/Dolores Seijo.jpg'

export default function Byography_Professor_DoloresSeijo() {
    const navigate = useNavigate();

    const handleGoBack = () => {
        navigate(-1);
    };

    return (
        <>
            <div className="flex items-center justify-start mb-8 md:mb-10 mt-20 md:mt-20 p-6 md:p-8">
                <button
                    className="flex items-center justify-center text-white bg-gradient-to-r from-blue-500 to-purple-500 px-4 py-2 focus:outline-none border  rounded-md transition duration-300"
                    onClick={handleGoBack}
                >
                    <SlActionUndo className="text-xl" />
                    <span className="ml-2">Back</span>
                </button>
            </div>
            <div className="mb-8 md:mb-10 p-6 md:p-8 bg-white rounded-md shadow-md max-w-2xl mx-auto">
                <h2 className="text-3xl md:text-4xl text-center font-semibold mb-4 md:mb-6 text-slate-900">
                Professor Dolores Seijo
                </h2>
                <div className="flex flex-col items-center space-y-4">
                    <img
                        src={Dolores}
                        alt="Dolores"
                        className="rounded-full h-40 md:h-60 w-40 md:w-60 mb-2 md:mb-4 object-cover"
                    />
                    <p className="text-sm md:text-base text-gray-800 text-justify">

                    Dolores Seijo is Full Professor of Social Psychology at the University of Santiago de Compostela (2010-). Her main academic link is associated with the Degree in Criminology and the specialty of the Official Master's Degree in Legal-Forensic Psychology of the USC. Member of the research team (GI-1754 of the USC, Forensic Psychology Institute of the university of Santiago de Compostela which is also a service of the Faculty of Psychology, serving as forensic psychologist (knowledge transfer) in the Institute.  Her main line of research is associated with the area of Legal and Forensic Psychology of the Family and Children, specifically in the study of family dynamics linked to situations of divorce and breakup of couples with children and associated psycholegal situations (family crises, high conflict, violence, or other circumstances). She has participated in research projects related to the study of parenting and therapeutic jurisprudence. Author and co-author of 54 articles, 11 books, 117 collective works.
                    </p>
                </div>
            </div>
        </>
    );
}
