import React, { useState, useEffect, useRef } from 'react';
import { FaRegClock } from 'react-icons/fa';
import { initializeApp } from 'firebase/app';
import { getDatabase, ref, onValue, set, get, push } from 'firebase/database';
import firebaseConfig from '../firebaseConfig';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FaTelegramPlane } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import emailjs from '@emailjs/browser';

// Inicializa o aplicativo Firebase com a configuração fornecida
initializeApp(firebaseConfig);
const firebaseApp = initializeApp(firebaseConfig);
const database = getDatabase(firebaseApp);

export default function Time() {
  const [countdown, setCountdown] = useState('');
  const [showToggle, setShowToggle] = useState(false);
  const [showEndMessage, setShowEndMessage] = useState(false);
  const [countdownFinished, setCountdownFinished] = useState(false);
  const [formulario, setFormulario] = useState(false);
  const [formError, setFormError] = useState(false);
  const navigate = useNavigate();
  const firstEmptyFieldRef = useRef(null);
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);

  const togglePasswordVisibility = () => {
    setIsPasswordVisible((prev) => !prev);
  };


  const handleClick2 = () => {
    navigate('/submissions');
  };

  const handleForm = () => {
    // Verifique se a contagem regressiva terminou
    if (countdownFinished) {
      // Opcionalmente, você pode exibir uma mensagem ou realizar outras ações específicas para o estado de término.
      /* console.log('Contagem regressiva terminou. Envio de formulário não permitido.'); */
    }

    // Se o formulário estiver aberto, feche-o automaticamente
    if (formulario && countdownFinished) {
      setFormulario(false);
      /* console.log('Fechando automaticamente o formulário após a contagem regressiva.'); */
      return;
    }

    // Se a contagem regressiva não terminou, alterne o estado do formulário normalmente
    if (!countdownFinished) {
      setFormulario(!formulario);
      setFormError(false);
    }
  };




  const handleSubmit = async (event) => {
    event.preventDefault();
    toast.success('Informação enviada com sucesso!', { autoClose: 3000 });

    const formData = {
      submissionType: event.target.submissionType.value,
      title: event.target.title.value,
      authors: event.target.authors.value,
      presentingAuthor: event.target.presentingAuthor.value,
      contact: event.target.contact.value,
      abstract: event.target.abstract.value,
      keywords: event.target.keywords.value,
    };

    const emailjsParams = {
      serviceId: 'service_ptyzt19',
      templateId: 'template_ky8onvl',
      publicKey: 'OqSyF24145VrRotBk',
    };

    event.target.reset();
    try {
      const response = await emailjs.send(
        emailjsParams.serviceId,
        emailjsParams.templateId,
        formData,
        emailjsParams.publicKey
      );
    } catch (error) {
      console.error('Erro ao enviar e-mail:', error);
    }

    const anyFieldEmpty = Object.values(formData).some((value) => value.trim() === '');

    if (anyFieldEmpty) {
      setFormError(true);
      for (const key in formData) {
        if (formData[key].trim() === '' && firstEmptyFieldRef.current) {
          firstEmptyFieldRef.current.focus();
          break;
        }
      }
      return;
    }

    setTimeout(() => {
      try {
        push(ref(database, 'submissoes'), formData);
        setFormError(false);
      } catch (error) {
        console.error('Erro ao enviar dados para o Firebase:', error);
      }
    }, 500);
  };


/* 
  const countdownRef = ref(getDatabase(), 'countdown');
  useEffect(() => {
    let interval;
  
    const getTargetTime = () => {
      const targetDate = new Date(2024, 3, 8);
      targetDate.setHours(0, 0, 0, 0);
      return targetDate.getTime();
    };
  
    const handleCountdown = () => {
      const targetTime = getTargetTime();
  
      interval = setInterval(() => {
        const now = new Date().getTime();
        const distance = targetTime - now;
  
        if (distance <= 0) {
          clearInterval(interval);
          const finished = 'Countdown finished';
          setCountdown(finished);
          setCountdownFinished(true);
          set(ref(getDatabase(), 'countdown'), finished);
          setShowEndMessage(true);
          setShowToggle(false);
        } else {
          const daysRemaining = Math.floor(distance / (1000 * 60 * 60 * 24));
          const hoursRemaining = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
          const minutesRemaining = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
          const secondsRemaining = Math.floor((distance % (1000 * 60)) / 1000);
  
          setCountdown(`${daysRemaining}Days   ${hoursRemaining}Hours  ${minutesRemaining}Minutes  ${secondsRemaining}Seconds`);
        }
      }, 1000);
    };
  
    // Verifique o valor de 'countdown' no banco de dados ao montar o componente
    const countdownValue = get(ref(getDatabase(), 'countdown'));
    if (countdownValue !== 'Contagem regressiva finalizada') {
      handleCountdown(); // Inicie a contagem regressiva se ela ainda não tiver terminado
    }
  
    const cleanup = () => {
      clearInterval(interval);
    };
  
    // Execute a limpeza quando o componente for desmontado
    return cleanup;
  }, []); */
  

  


  const handleToggleClick = () => {
    if (countdown !== 'Contagem regressiva finalizada' && !countdownFinished) {
      setShowToggle((prevValue) => !prevValue);
    }
  };
  return (
    <div className='mt-4'>
     {/*  <div className="text-center bg-gradient-to-r from-blue-500 to-purple-500 text-white p-8 rounded-md shadow-md mb-8">
        <h2 className="text-3xl font-bold mb-4">Countdow Clock</h2>
        <FaRegClock size={90} className="mx-auto mb-4 rotate" />
        {typeof countdown === 'string' && (
          <p className="text-xl">{countdown}</p>
        )}

        {showEndMessage && (
          <div className="bg-yellow-500 text-white p-2 rounded-md mt-4">
            <p>Hello! I hope all is well with you. I would like to inform you that the submission deadline for the conference has already closed..</p>
            <p>We appreciate your interest and hope to see you at future events. If you have any questions, don't hesitate to get in touch. Have a great day!😕</p>
          </div>
        )}
      </div> */}
      <div className="flex justify-center mb-8">
        {countdown !== 'Contagem regressiva finalizada' && (
          <button
            onClick={handleForm}
            className="bg-gradient-to-r from-blue-500 to-purple-500 text-white mt-8 px-8 py-3 rounded-full hover:bg-blue-600 transition duration-300"

          >
            Submit Abstract Here
          </button>
        )}
      </div>

      {formulario && (
        <form
          onSubmit={handleSubmit}
          className=" max-w-xl mx-auto mt-8 bg-white p-8   rounded-md shadow-md"
        >
          <div className="mb-4">
            <div className="text-right">
              <button onClick={handleClick2} className="text-blue-700">
                Submission Guidelines <span className="text-red-500">*</span>
              </button>
            </div>

            <label htmlFor="submissionType" className="block text-sm font-medium text-gray-700">
              Submission Type
            </label>
            <select
              id="submissionType"
              name="submissionType"
              ref={firstEmptyFieldRef}
              className="mt-1 p-2 border rounded-md w-full focus:outline-none focus:ring focus:border-blue-300"
            >
              <option value="panelSession">Panel Session</option>
              <option value="oralPresentation">Oral Presentation</option>
              <option value="talkingPoster">Talking Poster</option>
            </select>
          </div>

          <div className="mb-4">
            <label htmlFor="title" className="block text-sm font-medium text-gray-700">
              Title of the Presentation <span className="text-red-500">*</span>
            </label>
            <input
              type="text"
              id="title"
              name="title"
              required
              className="mt-1 p-2 border rounded-md w-full focus:outline-none focus:ring focus:border-blue-300"
            />
          </div>

          <div className="mb-4">
            <label htmlFor="authors" className="block text-sm font-medium text-gray-700">
              Author(s) of the Presentation <span className="text-red-500">*</span>
            </label>
            <input
              type="text"
              id="authors"
              name="authors"
              required
              className="mt-1 p-2 border rounded-md w-full focus:outline-none focus:ring focus:border-blue-300"
            />
          </div>

          <div className="mb-4">
            <label htmlFor="presentingAuthor" className="block text-sm font-medium text-gray-700">
              Presenting Author <span className="text-red-500">*</span>
            </label>
            <input
              type="text"
              id="presentingAuthor"
              name="presentingAuthor"
              required
              className="mt-1 p-2 border rounded-md w-full focus:outline-none focus:ring focus:border-blue-300"
            />
          </div>

          <div className="mb-4">
            <label htmlFor="contact" className="block text-sm font-medium text-gray-700">
              Presenting Author Contact <span className="text-red-500">*</span>
            </label>
            <input
              type="text"
              id="contact"
              name="contact"
              required
              className="mt-1 p-2 border rounded-md w-full focus:outline-none focus:ring focus:border-blue-300"
            />
          </div>

          <div className="mb-4">
            <label htmlFor="abstract" className="block text-sm font-medium text-gray-700">
              Abstract (up to 300 words) <span className="text-red-500">*</span>
            </label>
            <textarea
              id="abstract"
              name="abstract"
              rows="4"
              required
              className="mt-1 p-2 border rounded-md w-full focus:outline-none focus:ring focus:border-blue-300"
            ></textarea>
          </div>

          <div className="mb-4">
            <label htmlFor="keywords" className="block text-sm font-medium text-gray-700">
              Keywords (up to 5 words) <span className="text-red-500">*</span>
            </label>
            <div className="relative">
              <input
                type="text"/* {isPasswordVisible ? 'text' : 'password'} */
                id="keywords"
                name="keywords"
                required
                className="mt-1 p-2 border rounded-md w-full focus:outline-none focus:ring focus:border-blue-300"
              />
              {/* <button
                type="button"
                onClick={togglePasswordVisibility}
                className="absolute inset-y-0 right-0 pr-3 flex items-center"
              >
                {isPasswordVisible ? (
                  <svg
                    className="h-5 w-5 text-gray-500"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fillRule="evenodd"
                      d="M10 12a2 2 0 100-4 2 2 0 000 4zM10 2a7 7 0 00-7 7 7 7 0 0014 0 7 7 0 00-7-7z"
                      clipRule="evenodd"
                    />
                  </svg>
                ) : (
                  <svg
                    className="h-5 w-5 text-gray-500"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fillRule="evenodd"
                      d="M10 18a8 8 0 100-16 8 8 0 000 16zM10 2a6 6 0 100 12 6 6 0 000-12z"
                      clipRule="evenodd"
                    />
                  </svg>
                )}
              </button> */}
            </div>
          </div>

          <div className="mb-4 flex">
            <button
              type="submit"
              className="bg-gradient-to-r from-blue-500 to-purple-500 text-white px-8 py-3 rounded-full flex items-center justify-center w-full max-w-2xl hover:bg-blue-600 transition duration-300"
            >
              <span className="mr-2">Submit</span>
              <FaTelegramPlane size={20} /> {/* Use o ícone do Telegram */}
            </button>

          </div>


          {formError && (
            <p className="text-red-500 text-sm mb-4">Por favor, preencha todos os campos antes de enviar.</p>
          )}
        </form>
      )}
      <ToastContainer />
    </div>
  );
}
