import React, { useState } from 'react';
import Compostela from '../images/santiago.jpg';
import Faculdade from '../component/images/Faculdade de Psicologia.jpg';
import { IoCarSport } from "react-icons/io5";
import { FaBusSimple } from "react-icons/fa6";

const VenueAndTravelTab = () => {
  const [selectedTab, setSelectedTab] = useState('welcome');
  const infoAddress = {
    latitude:  42.87474,
    longitude: -8.56094,
    address: "Faculty of Psychology of USC, Rúa Xosé María Suárez Núñez, s/n. Campus Vida, 15782, Santiago de Compostela"
  };

  return (
    <div className="mt-8 container mx-auto px-4">
      <h1 className="text-4xl font-bold mb-6 mt-40 text-center text-gray-700"></h1>
      <div className="mb-4 flex flex-wrap justify-center">
        <button
          className={`mb-2 mr-4 px-4 py-2 focus:outline-none ${selectedTab === 'welcome' ? 'border-b-2 border-blue-500' : ''}`}
          onClick={() => setSelectedTab('welcome')}
        >
          Welcome
        </button>
        <button
          className={`mb-2 mr-4 px-4 py-2 focus:outline-none ${selectedTab === 'venueTravel' ? 'border-b-2 border-blue-500' : ''}`}
          onClick={() => setSelectedTab('venueTravel')}
        >
          Venue & Travel
        </button>
        <button
          className={`mb-2 px-4 py-2 focus:outline-none ${selectedTab === 'transportation' ? 'border-b-2 border-blue-500' : ''}`}
          onClick={() => setSelectedTab('transportation')}
        >
          Transportation
        </button>
        <button
          className={`mb-2 mr-4 px-4 py-2 focus:outline-none ${selectedTab === 'Accommodation' ? 'border-b-2 border-blue-500' : ''}`}
          onClick={() => setSelectedTab('Accommodation')}
        >
          Accommodation
        </button>
        <button
          className={`mb-2 px-4 py-2 focus:outline-none ${selectedTab === 'Social programme' ? 'border-b-2 border-blue-500' : ''}`}
          onClick={() => setSelectedTab('Social programme')}
        >
          Social programme
        </button>
      </div>

      {selectedTab === 'welcome' && (
       <div>
       <h1 className="text-3xl font-bold mb-6 mt-16 text-center text-gray-700">Welcome to Santiago de Compostela</h1>
       <p className="text-lg text-gray-500  mb-8">
         Welcome to Santiago de Compostela, the capital of the Autonomous Community of Galicia; declared a World Heritage City
         by UNESCO thanks to its monumental beauty, extraordinary conservation, and as the final destination of a thousand-year-old
         pilgrim route: the Way of St. James, which, since the 9th century, has transformed this finis terrae into a meeting place
         of Western faith and thinking.
       </p>
       <div className="mb-8">
         <img src={Compostela} alt="Santiago de Compostela" className="w-full h-auto rounded-md shadow-lg" />
       </div>
     </div>
      
      )}

      {selectedTab === 'venueTravel' && (
         <div>
         <h2 className="text-3xl font-bold mb-6 mt-16 text-center text-gray-700">Venue and Travel</h2>
         <div>
  <p className="text-lg mb-6 text-gray-500">
    The 19th Conference of The International Academy of Investigative Psychology will take place in the Faculty of Psychology
    of the University of Santiago de Compostela.
  </p>
  <div className="mb-8">
    <img src={Faculdade} alt="Faculty of Psychology" className="w-full h-auto rounded-md shadow-lg" />
  </div>
  <div className="text-lg mb-6 text-gray-500">
    <p>
      Welcome to the Faculty of Psychology at USC, a center distinguished by its commitment to quality teaching (awarded Institutional Accreditation in 2019 by the Council of Universities), research excellence, and a strong dedication to bridging academic knowledge with the professional field of psychology.
    </p>
    <br />
    <p>
      The Faculty of Psychology is home to leading research groups in various domains of Spanish research. In recent years, there has been a remarkable increase in funded projects and publications in prestigious international journals by faculty groups.
    </p>
    <br />
    <p>
      In addition to these objectives, the Faculty is dedicated to promoting social context, equality, and sustainability as democratic values that guide all actions of a public education institution.
    </p>
  </div>
</div>


         <div className="mb-8">
           <iframe
             title="Google Maps"
             src={`https://www.google.com/maps/embed/v1/place?key=AIzaSyDamAjwKIccymT0RJzeZDJ1i_I71PSVUu4&q=${infoAddress.latitude},${infoAddress.longitude}`}
             width="100%"
             height="400"
             allowFullScreen
             className="rounded-md shadow-lg"
           ></iframe>
         </div>

         <div className="mb-8">
           <div className="mb-4">
             <h3 className="text-xl font-bold mb-2 text-gray-700">Address</h3>
             <p className='text-gray-500'>Faculty of Psychology of USC</p>
             <p className='text-gray-500'>Rúa Xosé María Suárez Núñez, s/n. Campus Vida</p>
             <p className='text-gray-500'>15782, Santiago de Compostela</p>
           </div>
           <div className="mb-4">
             <h3 className="text-xl font-bold mb-2  text-gray-700">Website</h3>
             <p>
               <a
                 href="https://www.usc.gal/en/center/faculty-psychology"
                 target="_blank"
                 rel="noopener noreferrer"
                 className="text-blue-500"
               >
                 https://www.usc.gal/en/center/faculty-psychology
               </a>
             </p>
           </div>
         </div>
       </div>
      )}

      {selectedTab === 'transportation' && (
        <div className="bg-white rounded-lg p-8 shadow-md">
          <div className="mb-8">
            <h3 className="text-3xl font-bold mb-6 mt-16 text-center text-gray-700">
              Transportation to and from Santiago de Compostela Airport
            </h3>

            <div className="mb-4">
              <h4 className="text-lg font-bold mb-2 text-gray-700 flex items-center">
                <IoCarSport className="h-6 w-6 mr-2" />
                Car
              </h4>
              <p className="text-gray-500">
                The distance from Santiago de Compostela Airport to the historic city centre is 15 kilometres / 9.3
                miles, and the journey time by car is 20 minutes.
              </p>
            </div>

            <div className="mb-4">
              <h4 className="text-lg font-bold mb-2 text-gray-700 flex items-center">
                <FaBusSimple className="h-6 w-6 mr-2" />
                Public Transport
              </h4>
              <div className="text-gray-500 ">
        <p className='mt-4'>
            Public transportation in the city and surrounding area is operated by Empresa Trulusa. From the airport, bus line 6 leaves in the direction of Os Tilos, via the centre of Santiago de Compostela. Bus line 6 between the airport and the city replaced the bus service of Empresa Freire, responsible for bus transport from Santiago de Compostela Airport to Santiago and Lugo until December 2020. It takes 20 minutes to get to the city’s bus station (Avenida de Rodríguez de Viguri bus stop), 25 minutes to the centre of Santiago de Compostela (Praza de Galicia) and 30 minutes to the city’s railway station (Hórreo Estación bus stop).
        </p>
        <p className=' mt-4'> 
          Buses run every 20 to 30 minutes all days of the week between 07:00 hours and 23:00 hours. One-way tickets cost only € 1.00 and are available from the bus driver. You can also buy a ticket for 10, 20 or 30 bus rides called Bono. If you choose this option, the price for a single journey (maximum 60 minutes) is only € 0.60. A Bono card can be bought on board the bus and costs € 3.00.
        </p>
      </div>
            </div>
          </div>
        </div>
      )}

      {selectedTab === 'Accommodation' && (
        <div>
          <h2 className="text-3xl font-bold mb-6 mt-16 text-center text-gray-700">Accommodation</h2>
          <p className="text-lg mb-6 text-center text-gray-500">
          The Organizing Committee established a protocol with the Eurostars Company that guarantees a 10% discount on the Eurostars Gran Hotel Santiago hotel rate (see <a href="https://www.eurostarshotels.co.uk/eurostars-gran-hotel-santiago.html" className="text-blue-500 underline" target="_blank" rel="noopener noreferrer">https://www.eurostarshotels.co.uk/eurostars-gran-hotel-santiago.html</a>). To request the discount code, send an email to the Conference Secretariat at mercedes.novo@usc.es.
          </p>
        </div>
      )}

      {selectedTab === 'Social programme' && (
        <div>
          <h2 className="text-3xl font-bold mb-6 mt-16 text-center text-gray-700">Social programme</h2>
          <div className="max-w-4xl mx-auto p-6 bg-white rounded-lg shadow-lg">
  <div className="mb-10">
    <h2 className="text-2xl font-bold text-center text-gray-600 mb-4">Conference Social Event</h2>
    <p className="text-center text-gray-600 mb-6">
    We organize a walking tour for participants on Wednesday evening, which is free. This will be a guided tour of the historic center. More details regarding this will be sent soon. Contact the secretariat for more details.    </p>
  </div>
  
  <div>
    <h2 className="text-2xl font-bold text-center text-gray-600 mb-4">Conference Dinner</h2>
    <p className="text-center text-gray-600">
On Thursday, a conference dinner will be held at the Eurostar Gran Hotel Santiago. You can express your interest in participating by contacting the secretariat and payment will be made on the morning of registration.    </p>
  </div>
</div>

        </div>
      )}
    </div>
  );
};

export default VenueAndTravelTab;
