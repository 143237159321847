import React from 'react';
import { SlActionUndo } from "react-icons/sl";
import { useNavigate } from 'react-router-dom';
import Manuel from '../biography/images/Manuel Vilariño.jpg'

export default function Byography_Professor_Manuel_Vilariño() {
    const navigate = useNavigate();

    const handleGoBack = () => {
        navigate(-1);
    };

    return (
        <>
            <div className="flex items-center justify-start mb-8 md:mb-10 mt-20 md:mt-20 p-6 md:p-8">
                <button
                    className="flex items-center justify-center text-white bg-gradient-to-r from-blue-500 to-purple-500 px-4 py-2 focus:outline-none border  rounded-md transition duration-300"
                    onClick={handleGoBack}
                >
                    <SlActionUndo className="text-xl" />
                    <span className="ml-2">Back</span>
                </button>
            </div>
            <div className="mb-8 md:mb-10 p-6 md:p-8 bg-white rounded-md shadow-md max-w-2xl mx-auto">
                <h2 className="text-3xl md:text-4xl text-center font-semibold mb-4 md:mb-6 text-slate-900">
                    Professor Manuel Vilariño
                </h2>
                <div className="flex flex-col items-center space-y-4">
                    <img
                        src={Manuel}
                        alt="Ramón"
                        className="rounded-full h-40 md:h-60 w-40 md:w-60 mb-2 md:mb-4 object-cover"
                    />
                    <p className="text-sm md:text-base text-gray-800 text-justify">

                        Manuel Vilariño is a Full Professor at the University of Santiago de Compostela (Spain) and a member of the Forensic Psychology Institute of the University of Santiago de Compostela. Previously, he was a professor at the Universidade Lusófona do Porto (Portugal), the University of Vigo (Spain), and the University of Zaragoza (Spain). His research focuses on forensic psychological assessment, credibility assessment, criminal psychology, and any other issue related with forensic psychology. He is the author of scientific articles in specialized journals indexed in major databases (WOS and SCOPUS) and book chapters. Additionally, he has made multiple contributions to scientific congress. He has an H-index of 19 in Google Scholar and more than 2009 citations, and an H-index of 11 in the Web of Science and more than 349 citations.
                    </p>
                </div>
            </div>
        </>
    );
}
