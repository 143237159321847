import React from 'react';
import { SlActionUndo } from "react-icons/sl";
import { useNavigate } from 'react-router-dom';
import Maria from '../biography/images/Maria.jpg'

export default function Biography_Professor_Maria_Ioannou() {
  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate(-1);
  };

  return (
    <>
      <div className="flex items-center justify-start mb-8 md:mb-10 mt-20 md:mt-20 p-6 md:p-8">
        <button
          className="flex items-center justify-center text-white bg-gradient-to-r from-blue-500 to-purple-500 px-4 py-2 focus:outline-none border  rounded-md transition duration-300"
          onClick={handleGoBack}
        >
          <SlActionUndo  className="text-xl" />
          <span className="ml-2">Back</span>
        </button>
      </div>
      <div className="mb-8 md:mb-10 p-6 md:p-8 bg-white rounded-md shadow-md max-w-2xl mx-auto">
        <h2 className="text-3xl md:text-4xl text-center font-semibold mb-4 md:mb-6 text-slate-900">
        Professor Maria Ioannou
        </h2>
        <div className="flex flex-col items-center space-y-4">
          <img
            src={Maria}
            alt="Maria"
            className="rounded-full h-40 md:h-60 w-40 md:w-60 mb-2 md:mb-4 object-cover"
          />
          <p className=" text-justify text-sm md:text-base text-gray-800 ">
          Professor  Maria Ioannou  (BSc, MSc, PhD, C.Psychol, CSci, AFBPsS, FHEA, CMgr, MCMI), is a Professor of Investigative and Forensic Psychology, Director for two postgraduate courses: MSc Investigative Psychology and MSc Security Science and ex-Director of the Secure Societies Institute at the University of Huddersfield, UK. She is also a Chartered Forensic Psychologist (BPS), Health and Care Professions Council (HCPC) Registered Psychologist, Chartered Scientist (BPS), Chartered Manager (Chartered Management Institute), Fellow of the Higher Education Academy, an Associate Fellow of the British Psychological Society and board member of the Institute on Crime and Criminal Justice Policy, European Public Law Organisation (EPLO).
            Prof. Dr. Ioannou has considerable experience in exploring offender and victim profiles and has been involved in the assessment and development of intervention programmes for reducing/preventing crime for a range of different forms of criminality and groups of offenders and ‘at risk’ individuals as well as impact delivery. She has delivered a variety of courses to professionals as well as applied research, case reports and consultancy projects to police, government authorities, intervention agencies and other public bodies and has contributed to various legal cases. Prof. Dr. Ioannou has been invited to train law enforcement and security personnel as well as give lectures and seminars in a number of countries in Europe, Middle East and South East Asia. She has an extensive publication record, media experience across all platforms appearing on CNN and BBC radio and her work has been presented nationally and internationally.

          </p>
        </div>
      </div>
    </>
  );
}