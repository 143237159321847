import React, { useState } from 'react';
import { BrowserRouter as Router, Link } from 'react-router-dom';
import IAIP from '../images/IAIP LOGO - low quality.jpg'
import AppRoutes from '../routes/Routes';

const Navigation = ({toggleMenu}) => {
  return (
    <nav className="bg-gradient-to-r from-blue-500 to-purple-500 p-4 fixed top-0 w-full z-10">
      <div className="container mx-auto flex justify-between items-center">


        <Link to="/home" element={<Home />} className="text-white text-lg font-bold flex items-center">
          <img src={IAIP} alt="IAIP logo" className="w-12 h-12 mr-2 rounded-full" />
          <span className="hidden md:inline-block">IAIP 19th Conference</span>
        </Link>

        <div className="hidden md:flex ">
          <Link
            to="/home"
            className="text-white hover:text-gray-300 px-4 font-semibold transition duration-300 ease-in-out"
          >
            Home
          </Link>
          <Link
            to="/organizers"
            className="text-white hover:text-gray-300 px-4 font-semibold transition duration-300 ease-in-out"
          >
            Organisers
          </Link>
          
          <Link
            to="/Secretariat"
            className="text-white hover:text-gray-300 px-4 font-semibold transition duration-300 ease-in-out"
          >
            Secretariat
          </Link>
          <Link
            to="/ScientificCommittee"
            className="text-white hover:text-gray-300 px-4 font-semibold transition duration-300 ease-in-out"
          >
            Scientific Committee
          </Link>
          <Link
            to="/papers"
            className="text-white hover:text-gray-300 px-4 font-semibold transition duration-300 ease-in-out"
          >
            Call for Papers
          </Link>

          <Link
            to="/programme"
            className="text-white hover:text-gray-300 px-4 font-semibold transition duration-300 ease-in-out"
          >
            Programme
          </Link>

          <Link
            to="/information"
            className="text-white hover:text-gray-300 px-4 font-semibold transition duration-300 ease-in-out"
          >
            General Information
          </Link>

          <Link
            to="/registration"
            className="text-white hover:text-gray-300 px-4 font-semibold transition duration-300 ease-in-out"
          >
            Registration
          </Link> 

          <Link
            to="/contact"
            className="text-white hover:text-gray-300 px-4 font-semibold transition duration-300 ease-in-out"
          >
            Contact
          </Link>
        
        </div>
       {/*  <Link to="/home" className="text-white text-lg font-bold flex items-center">
          <img src={IAIP} alt="IAIP logo" className="w-12 h-12 mr-2 rounded-full" />
          <span className="hidden md:inline-block">IAIP 19th Conference</span>
        </Link> */}

           <div className="md:hidden">
          <button
            className="text-white text-xl focus:outline-none"
            onClick={toggleMenu}
          >
            ☰ 
          </button>
        </div>
      </div>
    </nav>
  );
};

const Menu = ({ menuOpen, toggleMenu }) => {
  return (
   <>
    <div
      className={`bg-gradient-to-r from-blue-500 to-purple-500  p-4 fixed top-0 left-0 w-64 h-full z-10 transition-transform duration-300 ${menuOpen ? 'translate-x-0' : '-translate-x-full'
        }`}
    >
      
      
      <ul className="flex flex-col space-y-4">
        <li>
          <Link
            to="/home"
            className="text-white hover:text-gray-300 font-semibold transition duration-300 ease-in-out"
            onClick={toggleMenu}
          >
            Home
          </Link>
        </li>
        <li>
          <Link
            to="/organizers"
            className="text-white hover:text-gray-300 font-semibold transition duration-300 ease-in-out"
            onClick={toggleMenu}
          >
            Organisers
          </Link>
        </li>
        <li>
          <Link
            to="/ScientificCommittee"
            className="text-white hover:text-gray-300 font-semibold transition duration-300 ease-in-out"
            onClick={toggleMenu}
          >
            Scientific Committee
          </Link>
        </li>
        {/* novo */}
        <li>
          <Link
            to="/Secretariat"
            className="text-white hover:text-gray-300 font-semibold transition duration-300 ease-in-out"
            onClick={toggleMenu}
          >
            Secretariat

          </Link>
        </li>
        <li>
          <Link
            to="/papers"
            className="text-white hover:text-gray-300 font-semibold transition duration-300 ease-in-out"
            onClick={toggleMenu}
          >
            Call for Papers
          </Link>
        </li>
        <li>
          <Link
            to="/programme"
            className="text-white hover:text-gray-300 font-semibold transition duration-300 ease-in-out"
            onClick={toggleMenu}
          >
            Programme
          </Link>
        </li>
        <li>
          <Link
            to="/information"
            className="text-white hover:text-gray-300 font-semibold transition duration-300 ease-in-out"
            onClick={toggleMenu}
          >
            General Information
          </Link>
        </li>

        <li>
          <Link
            to="/registration"
            className="text-white hover:text-gray-300 font-semibold transition duration-300 ease-in-out "
            onClick={toggleMenu}
          >
            Registration
          </Link>
        </li>
        <li>
          <Link
            to="/contact"
            className="text-white hover:text-gray-300 font-semibold transition duration-300 ease-in-out"
            onClick={toggleMenu}
          >
            Contact
          </Link>
        </li>
        
      </ul>
    </div>
   </>
  );
};

const Home = () => {
  return (
    <div>
      <h1 >Home Page</h1>
    </div>
  );
};


const Navbar = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const toggleMenu = () => {
    setMenuOpen((prevMenuOpen) => !prevMenuOpen);
  };
  return (
    <Router>
      <>
        <Navigation menuOpen={menuOpen} toggleMenu={toggleMenu} />
        <Menu menuOpen={menuOpen} toggleMenu={toggleMenu} />
        <AppRoutes />
      </>
    </Router>
  );
};

export default Navbar;