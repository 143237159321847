import React from 'react';
import { SlActionUndo } from "react-icons/sl";
import { useNavigate } from 'react-router-dom';
import Telma from '../biography/images/Telma Almeida.jpg'

export default function Byography_Professor_DoloresSeijo() {
    const navigate = useNavigate();

    const handleGoBack = () => {
        navigate(-1);
    };

    return (
        <>
            <div className="flex items-center justify-start mb-8 md:mb-10 mt-20 md:mt-20 p-6 md:p-8">
                <button
                    className="flex items-center justify-center text-white bg-gradient-to-r from-blue-500 to-purple-500 px-4 py-2 focus:outline-none border  rounded-md transition duration-300"
                    onClick={handleGoBack}
                >
                    <SlActionUndo className="text-xl" />
                    <span className="ml-2">Back</span>
                </button>
            </div>
            <div className="mb-8 md:mb-10 p-6 md:p-8 bg-white rounded-md shadow-md max-w-2xl mx-auto">
                <h2 className="text-3xl md:text-4xl text-center font-semibold mb-4 md:mb-6 text-slate-900">
                    Dr. Telma Sousa Almeida
                </h2>
                <div className="flex flex-col items-center space-y-4">
                    <img
                        src={Telma}
                        alt="Telma"
                        className="rounded-full h-40 md:h-60 w-40 md:w-60 mb-2 md:mb-4 object-cover"
                    />
                    <p className="text-sm md:text-base text-gray-800 text-justify">

                        Dr. Telma Sousa Almeida, an Assistant Professor at Ispa-Instituto Universitário, Portugal, and a researcher at the William James Center for Research, holds a Ph.D. from the University of Cambridge under the mentorship of Professor Michael E. Lamb.
                        Her research has significantly advanced the intersection of science and practice in forensic psychology, with a primary focus on improving investigative interviewing techniques for vulnerable victims and witnesses within the criminal justice system. Dr. Almeida’s work has redefined our understanding of memory capabilities in autistic children, prompting a thorough re-evaluation of interviewing techniques in legal proceedings. Her research has provided crucial insights for educators, empowering them to develop more effective learning and memory retention strategies. Moreover, her research extends to communication strategies with autistic individuals, influencing best practices in investigative interviewing and shaping how legal professionals engage with autistic witnesses. Her work has also equipped clinical psychologists and educators with valuable strategies to support autistic individuals in recalling information, ultimately facilitating improved therapeutic and educational outcomes.
                        Beyond academia, Dr. Almeida is an active participant in the COST Action IMPLEMENDEZ (CA22128), where she collaborates with international experts to implement the Méndez Principles, advocating for the ethical treatment of vulnerable individuals within the legal system. Her dedication to ethical and evidence-based practices is further reflected in her roles as Coordinator, Scientific Supervisor and Forensic Psychological Assessment Expert at the Forensic Department of Ispa Clinic.
                        Throughout her career, Dr. Almeida has seamlessly blended psychology practice, teaching, and scientific research. She has co-authored several scientific papers and book chapters in diverse fields, including forensic and developmental psychology, juvenile delinquency, peace psychology, and human rights. Dr. Almeida’s multifaceted contributions aim to drive positive changes within the criminal justice system, benefiting vulnerable populations and society as a whole.

                    </p>
                </div>
            </div>
        </>
    );
}
