import React, { useState, useRef } from 'react';
import { initializeApp } from 'firebase/app';
import { getDatabase, ref as dbRef, push } from 'firebase/database';
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import firebaseConfig from '../firebaseConfig';
import { GiArchiveRegister } from 'react-icons/gi';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { SlActionUndo } from 'react-icons/sl';
import { BsInfoCircle } from 'react-icons/bs';
import { AiOutlineCloudUpload } from 'react-icons/ai';
import emailjs from '@emailjs/browser';

const firebaseApp = initializeApp(firebaseConfig);
const database = getDatabase(firebaseApp);
const storage = getStorage(firebaseApp);

function gerarIdentificadorUnico() {
  return 'id: ' + Math.random().toString(36).substring(7);
}
function RegistrationForm() {

  const [showInfo, setShowInfo] = useState(false);
  const navigate = useNavigate();
  const fileInputRef = useRef(null);
  const formRef = useRef(null);

  const handleGoBack = () => {
    navigate(-1);
  };

  const toggleInfo = () => {
    setShowInfo(!showInfo);
  };


  const [formData, setFormData] = useState({
    name: '',
    apelido: '',
    profession: '',
    qualifications: '',
    knowledgeArea: '',
    willPresent: '',
    affiliation: '',
    email: '',
    selecting_events: [],
    paymentProof: null,
    universityStatement: null, // Adicione este campo ao estado do formulário
    registrationType: '',
  });

  const handleChange = (e) => {
    if (e.target.type === "checkbox") {
      if (formData[e.target.name].includes(e.target.value)) {
        setFormData({
          ...formData,
          [e.target.name]: formData[e.target.name].filter(
            (item) => item !== e.target.value
          ),
        });
      } else {
        setFormData({
          ...formData,
          [e.target.name]: [...formData[e.target.name], e.target.value],
        });
      }
    } else {
      setFormData({ ...formData, [e.target.name]: e.target.value });
    }
  };

  const handleClickcontact = () => {

    navigate('/contact');
  };


  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];

    if (selectedFile) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        paymentProof: selectedFile,
      }));
    }
  };

  const handleUniversityStatementChange = async (e) => {
    const selectedFile = e.target.files[0];

    if (selectedFile) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        universityStatement: selectedFile,
      }));
    }
  };

  const renderUniversityStatementField = () => {
    if (formData.registrationType === 'Student') {
      return (

        <label className="block">
          <span className="text-gray-700 font-bold">
            If you selected student, please upload your university statement
          </span><span className=' text-red-500'> *</span>

          <div className="file-input-container mt-4 p-2 border rounded-md w-full flex items-center focus:outline-none focus:ring focus:border-blue-300">
            {/* Your custom icon or button for file input */}
            <div className="custom-file-input-icon mr-2">
              <AiOutlineCloudUpload className="text-xl text-blue-500" />
            </div>

            {/* File input and selected file name */}
            <div className="file-input-wrapper flex items-center">
              <input
                required
                type="file"
                name="universityStatement"
                ref={fileInputRef}
                onChange={handleUniversityStatementChange}
                id='universityStatement'
                className="hidden"
              />

              {/* "Choose a file" text and selected file name */}
              <div className="file-input-name">
                {formData.universityStatement ? formData.universityStatement.name : 'No file selected'}
              </div>
            </div>
          </div>
        </label>

      );
    }
    return null;
  };



  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const formDataToSend = { ...formData };

      // Gerar um identificador único para este envio
      const identificadorEnvio = gerarIdentificadorUnico();

      // Adicione o identificador ao objeto formData
      formDataToSend.identificadorEnvio = identificadorEnvio;


      // Verifica se um arquivo foi selecionado antes de tentar enviá-lo
      if (formDataToSend.paymentProof) {
        const selectedFile = formDataToSend.paymentProof;

        const storageRef = ref(storage, `gs://iaip-web.appspot.com/${identificadorEnvio} || file: ${selectedFile.name}`);
        await uploadBytes(storageRef, selectedFile);
        const downloadURL = await getDownloadURL(storageRef);

        formDataToSend.paymentProof = downloadURL;
      }

      if (formDataToSend.universityStatement) {
        const selectedFile = formDataToSend.universityStatement;

        const storageRef = ref(storage, `gs://iaip-web.appspot.com/${identificadorEnvio} || file: ${selectedFile.name}`);
        await uploadBytes(storageRef, selectedFile);
        const downloadURL = await getDownloadURL(storageRef);

        formDataToSend.universityStatement = downloadURL;
      }

      // Push data to Firebase Realtime Database
      await push(dbRef(database, 'registration'), formDataToSend);
// Enviar e-mail usando EmailJS
const emailjsParams = {
  serviceId: 'service_ptyzt19',
  templateId: 'template_de1u59t',
  publicKey: 'OqSyF24145VrRotBk',
};

let universityStatement = {};
if (formData.universityStatement) {
  universityStatement = {
    filename: formData.universityStatement.name,
    path: formData.universityStatement.path,
    type: formData.universityStatement.type,
  };
}

let paymentProof = {};
if (formData.universityStatement) {
  paymentProof = {
    filename: formData.paymentProof.name,
    path: formData.paymentProof.path,
    type: formData.paymentProof.type,
  };
}


// Use o link do comprovante de pagamento armazenado no Firebase
let paymentLink = formDataToSend.paymentProof; // Substitua com o campo correspondente do seu objeto formData
let universityLink = formDataToSend.universityStatement; // Substitua com o campo correspondente do seu objeto formData


const emailData = {
  name: formData.name,
  apelido: formData.apelido,
  email: formData.email,
  registrationType: formData.registrationType,
  universityStatement:[universityStatement],
  universityLink: universityLink,
  affiliation: formData.affiliation,
  knowledgeArea: formData.knowledgeArea,
  profession: formData.profession,
  selecting_events: formData.selecting_events.join(', '), // se selecting_events for uma matriz
  willPresent: formData.willPresent,
  qualifications: formData.qualifications,
  paymentProof: [paymentProof],
  paymentLink: paymentLink // Adicione esta linha
};

await emailjs.send(
  emailjsParams.serviceId,
  emailjsParams.templateId,
  emailData,
  emailjsParams.publicKey
);



      toast.success('Information sent successfully!', { autoClose: 3000 });

      setFormData({
        name: '',
        apelido: '',
        profession: '',
        qualifications: '',
        knowledgeArea: '',
        willPresent: '',
        affiliation: '',
        email: '',
        selecting_events: '',
        paymentProof: null,
        universityStatement: null, // Limpe este campo também
      });

      // Clear the file input value
      if (fileInputRef.current) {
        fileInputRef.current.value = '';
      }

      // Reset the form using the formRef
      if (formRef.current) {
        formRef.current.reset();
      }
    } catch (error) {
      console.error('Error sending data to Firebase:', error);
      toast.error(`Error sending data: ${error.message}. Please try again.`);
    }
  };

  return (
    <>
      <div className="flex items-center justify-start mb-8 md:mb-10 mt-20 md:mt-20 p-6 md:p-8">
        <button
          className="flex items-center justify-center text-white bg-gradient-to-r from-blue-500 to-purple-500 px-4 py-2 focus:outline-none border  rounded-md transition duration-300"
          onClick={handleGoBack}
        >
          <SlActionUndo className="text-xl" />
          <span className="ml-2">Back</span>
        </button>
      </div>
      <div className="mx-auto max-w-md p-6 bg-white rounded-md shadow-2xl">
        <form ref={formRef} onSubmit={handleSubmit} className="space-y-4">
          <label className="block">
            <span className="text-gray-700 font-bold ">Registration type:</span><span className=' text-red-500'> *</span>
            <select
              id="registrationType"
              name="registrationType"
              value={formData.registrationType}
              onChange={handleChange}
              required
              className="mt-2 p-2 border rounded-md w-full focus:outline-none focus:ring focus:border-blue-300"
            >
              <option value="">Select...</option>
              <option value="Student">Student</option>
              <option value="Professional">Professional</option>
            </select>
          </label>
          {renderUniversityStatementField()}
          <label className="block">
            <span className="text-gray-700 font-bold">First Name</span> <span className=' text-red-500'>*</span>
            <input
              required
              type="text"
              name="name"
              id='name'
              value={formData.name}
              onChange={handleChange}
              className="mt-1 p-2 border rounded-md w-full focus:outline-none focus:ring focus:border-blue-300" />
          </label>
          <label className="block">
            <span className="text-gray-700 font-bold">Last Name</span> <span className=' text-red-500'>*</span>
            <input
              required
              type="text"
              name="apelido"
              id='apelido'
              value={formData.apelido}
              onChange={handleChange}
              className="mt-1 p-2 border rounded-md w-full focus:outline-none focus:ring focus:border-blue-300" />
          </label>
          <label className="block">
            <span className="text-gray-700 font-bold">Affiliation</span><span className=' text-red-500'> *</span>
            <input
              required
              type="text"
              name="affiliation"
              value={formData.affiliation}
              onChange={handleChange}
              id='affiliation'
              className="mt-1 p-2 border rounded-md w-full focus:outline-none focus:ring focus:border-blue-300" />
          </label>
          <label className="block">
            <span className="text-gray-700 font-bold">Field of knowledge/Expertise</span><span className=' text-red-500'> *</span>
            <input
              required
              type="text"
              name="knowledgeArea"
              id='knowledgeArea'
              value={formData.knowledgeArea}
              onChange={handleChange}
              className="mt-1 p-2 border rounded-md w-full focus:outline-none focus:ring focus:border-blue-300" />
          </label>
          <label className="block">
            <span className="text-gray-700 font-bold">Profession</span><span className=' text-red-500'> *</span>
            <select
              required
              name="profession"
              onChange={handleChange}
              id='profession'
              value={formData.profession}
              className="mt-1 p-2 border rounded-md w-full focus:outline-none focus:ring focus:border-blue-300">
              <option value="">Select...</option>
              <option value="crime scene investigator">crime scene investigator</option>
              <option value="criminologist">criminologist</option>
              <option value="law enforcement officer">law enforcement officer</option>
              <option value="legal practitioner">legal practitioner</option>
              <option value="psychiatrist">psychiatrist</option>
              <option value="psychologist">psychologist</option>
              <option value="researcher">researcher</option>
              <option value="social worker">social worker</option>
              <option value="student">student</option>
              <option value="teacher">teacher</option>
              <option value=" other"> other</option>
            </select>
          </label>
          <label className="block">
            <span className="text-gray-700 font-bold">Please select the events you want to attend</span><span className='text-red-500'> *</span>
            <div className="mt-1 space-y-2">
              <label className="flex items-center">
                <input
                  required
                  type="checkbox"
                  name="selecting_events"
                  value="IAIP 19th Conference"
                  checked={formData.selecting_events.includes('IAIP 19th Conference')}
                  onChange={handleChange}
                  className="form-checkbox h-4 w-4 text-blue-500"
                />
                <span className="ml-2 text-gray-700 ">IAIP 19th Conference</span>
              </label>
              <label className="flex items-center">
                <input
                  type="checkbox"
                  name="selecting_events"
                  value="Workshop in Investigative Interviewing"
                  checked={formData.selecting_events.includes('Workshop in Investigative Interviewing')}
                  onChange={handleChange}
                  className="form-checkbox h-4 w-4 text-blue-500"
                />
                <span className="ml-2 text-gray-700 ">Workshop in Investigative Interviewing</span>
              </label>
              <label className="flex items-center">
                <input
                  type="checkbox"
                  name="selecting_events"
                  value="Workshop in Investigative Psychology"
                  checked={formData.selecting_events.includes('Workshop in Investigative Psychology')}
                  onChange={handleChange}
                  className="form-checkbox h-4 w-4 text-blue-500"
                />
                <span className="text-gray-700 ml-2 ">Workshop in Investigative Psychology</span>
              </label>
            </div>
          </label>

          <label className="block">
            <span className="text-gray-700 font-bold">
              Do you plan to submit a proposal to present at the conference?
            </span>
            <span className=' text-red-500'> *</span>

            <div className="mt-1">
              <label className="mr-4">
                <input
                  type="radio"
                  required
                  name="willPresent"
                  onChange={handleChange}
                  value="sim"
                  checked={formData.willPresent === "sim"}
                  className="mr-1 mt-2 form-checkbox h-4 w-4 text-blue-500"
                />
                <span className="text-lg">Yes</span>
              </label>

              <label>
                <input
                  type="radio"
                  required
                  name="willPresent"
                  onChange={handleChange}
                  value="não"
                  checked={formData.willPresent === "não"}
                  className="mr-1 mt-2 form-checkbox h-4 w-4 text-blue-500"
                />
                <span className='text-lg'>No</span>
              </label>
            </div>
          </label>
          <label className="block">
            <span className="text-gray-700 font-bold">Academic Qualifications</span><span className=' text-red-500'> *</span>
            <select
              required
              name="qualifications"
              onChange={handleChange}
              id='qualifications'
              value={formData.qualifications}
              className="mt-1 p-2 border rounded-md w-full focus:outline-none focus:ring focus:border-blue-300">
              <option value="">Select...</option>
              <option value="BA">BA</option>
              <option value="BSc">BSc</option>
              <option value="LLM">LLM</option>
              <option value="MA">MA</option>
              <option value="MSc">MSc</option>
              <option value=" PhD"> PhD</option>
              <option value=" Other"> Other</option>
            </select>
          </label>
          <label className="block">
            <span className="text-gray-700 font-bold">E-mail</span><span className=' text-red-500'> *</span>
            <input
              required
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              id='email'
              className="mt-1 p-2 border rounded-md w-full focus:outline-none focus:ring focus:border-blue-300" />
          </label>





          <label className="block">
            <span className="text-gray-700 font-bold">Payment receipt</span><span className=' text-red-500'> *</span>
            <div className="file-input-container mt-4 p-2 border rounded-md w-full flex items-center focus:outline-none focus:ring focus:border-blue-300">
              {/* Your custom icon or button for file input */}
              <div className="custom-file-input-icon mr-2">
                <AiOutlineCloudUpload className="text-xl text-blue-500" />
              </div>

              {/* File input and selected file name */}
              <div className="file-input-wrapper flex items-center">
                <input
                  required
                  type="file"
                  name="paymentProof"
                  ref={fileInputRef}
                  onChange={handleFileChange}
                  id='paymentProof'
                  className="hidden"
                />

                {/* "Choose a file" text and selected file name */}
                <div className="file-input-name">
                  {formData.paymentProof ? formData.paymentProof.name : 'Upload proof of payment:'}
                </div>
              </div>
            </div>
          </label>
          <div className="mt-8 bg-blue-100 rounded-md p-4 border-l-4 border-blue-500">
            <div className="notifications-container mt-2">
              {showInfo && (
                <div className="info-container">
                  <div className="info bg-gradient-to-r from-blue-500 to-purple-500 text-white p-4 rounded-lg shadow-md max-w-md">
                    <div className="flex justify-between items-center">
                      <div className="info-prompt-wrap">
                        <p>
                          We are favoring registrations via bank transfer. To complete registration, make a bank transfer to the account below and upload proof (if it is impossible for you to use this form of payment, please contact us using the <button onClick={handleClickcontact} className="text-blue-600 underline">
                          contact form
                           </button>
                           ).
                        </p>
                      </div>
                      <div className="flex-shrink-0">
                        <BsInfoCircle className="info-svg text-2xl" onClick={toggleInfo} />
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {!showInfo && (
                <div className="info-icon text-blue-800 text-2xl cursor-pointer" onClick={toggleInfo}>
                  <BsInfoCircle />
                </div>
              )}
            </div>
            <div className=" text-base font-bold text-blue-950 mt-2">
              To guarantee your registration, make payment to:
              <p className=' mt-2 text-xs font-bold text-blue-950'>
                ASOCIACIÓN GALLEGA DE PSICOLOGÍA Y LEY
              </p>
              <p className=' mt-2 text-xs italic font-bold text-blue-950'>
                Rúa Cruza, 42, Entresuelo 4, Milladoiro, Ames 15895 A Coruña (Spain)
              </p>
            </div>
            <p className="mt-2 text-sm text-gray-600">
              <span className="font-bold">IBAN:</span><span className=' font-bold text-xs'>  ES93 2100 1455 1702 0050 1144</span><br />
              <span className="font-bold">BIC (SWIFT):</span> <span className=' font-bold text-xs'> CAIXESBBXXX</span>
            </p>
            <div className=' mt-6'>
              <i className=" text-sm font-bold text-gray-700">
                The data entered in the form is stored with IAIP for processing conference registrations and in case there are any follow-up questions. By sending the form, you are giving your consent for your data to be recorded and processed electronically.
              </i>
            </div>
          </div>


          <div className="mb-4 flex">
            <button
              type="submit"
              className="bg-gradient-to-r from-blue-500 to-purple-500 text-white px-8 py-3 rounded-full flex items-center justify-center w-full max-w-2xl hover:bg-blue-600 transition duration-300"
            >
              <span className="mr-2">Register</span>
              <GiArchiveRegister size={20} />
            </button>
          </div>
        </form>
        <ToastContainer />
      </div>
    </>
  );
}

export default RegistrationForm;