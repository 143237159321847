import React from 'react';
import { SlActionUndo } from "react-icons/sl";
import { useNavigate } from 'react-router-dom';
import Mercedes from '../biography/images/Mercedes_Novo.jpeg'

export default function Biography_Mercedes_Novo() {
    const navigate = useNavigate();

    const handleGoBack = () => {
        navigate(-1);
    };

    return (
        <>
            <div className="flex items-center justify-start mb-8 md:mb-10 mt-20 md:mt-20 p-6 md:p-8">
                <button
                    className="flex items-center justify-center text-white bg-gradient-to-r from-blue-500 to-purple-500 px-4 py-2 focus:outline-none border  rounded-md transition duration-300"
                    onClick={handleGoBack}
                >
                    <SlActionUndo className="text-xl" />
                    <span className="ml-2">Back</span>
                </button>
            </div>
            <div className="mb-8 md:mb-10 p-6 md:p-8 bg-white rounded-md shadow-md max-w-2xl mx-auto">
                <h2 className="text-3xl md:text-4xl text-center font-semibold mb-4 md:mb-6 text-slate-900">
                    Professor Mercedes Novo
                </h2>
                <div className="flex flex-col items-center space-y-4">
                    <img
                        src={Mercedes}
                        alt="Ramón"
                        className="rounded-full h-40 md:h-60 w-40 md:w-60 mb-2 md:mb-4 object-cover"
                    />
                    <p className="text-sm md:text-base text-gray-800 text-justify">
                        Mercedes Novo is Full Professor of Forensic Psychology at the University of Santiago de Compostela (USC) and member of the Forensic Psychology Institute of the USC since its creation (2002), as researcher and forensic psychologist (knowledge transfer) in the Institute.
                        Her research topics include antisocial and criminal behaviour, scholar bullying, gender violence, re-education of gender offenders, youth victimization process, Therapeutic Justice, and any other issue related with forensic psychology. She has published 72 peer reviewed scientific papers on these topics, has edited 28 books and 97 book chapters, and presented more than 100 conferences and communications to congresses. She has an H index of 20 in the Web of Science, 17 in Scopus, and more than 320,901 downloads in Researchgate. She has supervised 8 doctoral theses. From 1995 to the present, she has participated in a total of 32 research projects as a member of the research team. Likewise, as a result of her research achievements, she has participated in the transfer of knowledge in a significant number of agreements, contracts and R+D activities (among others, the General Council of the Judiciary, the Ministry of the Interior and Penitentiary Institutions, the Ministry of Education and Science, the Ministry of Equality, the Ministry of Labour and Social Affairs, the Xunta de Galicia and the local administration). For her research she has achieved 4 periods of six years (sexenios), 100% of all possible. Professor Novo has been awarded by the Spanish Ministry of Science and Education with the Spanish National Award in Educational Research in 2003 and 2004 editions by his research on the prediction of the criminal behaviour. She has also obtained the recognition of the I3 certification from the Ministry of Science and Innovation for her research career. Finally, since its creation, and currently, she is a member of the Board of Directors of the Ibero-American Association of Therapeutic Justice (AITJ), and of the Spanish Society of Legal and Forensic Psychology (SEPJF), as well as regularly participating in the organizing and scientific committee of national and international scientific events organized by these associations.
                    </p>
                </div>
            </div>
        </>
    );
}
