import React, { useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import styled, { keyframes } from 'styled-components';
import Time from '../pages/Time'

const marqueeAnimation = keyframes`
  0% {
    transform: translateX(100%);
    opacity: 0;
  }
  5% {
    opacity: 1;
  }
  95% {
    opacity: 1;
  }
  100% {
    transform: translateX(-100%);
    opacity: 0;
  }
`;

const MarqueeContainer = styled.div`
  overflow: hidden;
  white-space: nowrap;
  width: 100%;
  box-sizing: border-box;
  padding: 2px;
  border: 1px solid #ccc;
  border-radius: 5px;
  position: relative;
`;

const MarqueeText = styled.p`
  display: inline-block;
  margin: 0;
  animation: ${marqueeAnimation} 15s linear infinite;
`;
const CallforPapers = () => {
  const navigate = useNavigate();
  const handleClick = () => {
    navigate('/conferencia');
  };

  return (
    <div className="bg-gray-100 p-8 mt-20 md:p-16">
      <h3 className="text-4xl font-bold mb-6 text-center text-gray-800">
        Submit abstract
      </h3>

      <div className="text-lg text-center text-gray-700 mb-8">
        It is our pleasure to invite you to the 19th Conference of the International Academy of Investigative Psychology (IAIP) with the topic:
        <p className="mt-2">
          <em>Investigative Psychology: Global Developments in Behavioral Science Contributions to Policing.</em>
        </p>
        <div className="mt-4">
          IAIP is accepting proposals for our conference. The call for proposals is open for panel sessions, oral presentations, and talking posters on any aspect of the
          <button onClick={handleClick} className="text-blue-500 underline ml-2">
            Conference Themes.
          </button>
        </div>
      </div>
      <MarqueeContainer>
        <MarqueeText className=" text-slate-900 mt-8 p-3">
        {/*   Conference Themes
          <span className="mx-2 md:mx-4">|</span>
          Crime analysis
          <span className="mx-2 md:mx-4">|</span>
          Geographic profiling
          <span className="mx-2 md:mx-4">|</span>
          Investigative Interviewing
          <span className="mx-2 md:mx-4">|</span>
          Violent crime
          <span className="mx-2 md:mx-4">|</span>
          Terrorism and Radicalisation
          <span className="mx-2 md:mx-4">|</span>
          Domestic violence
          <span className="mx-2 md:mx-4">|</span>
          Sexual offending
          <span className="mx-2 md:mx-4">|</span>
          Courtroom Psychology
          <span className="mx-2 md:mx-4">|</span>
          Eyewitness testimony
          <span className="mx-2 md:mx-4">|</span>
          Predictive Policing
          <span className="mx-2 md:mx-4">|</span>
          Criminal Networks and Organised Crime
          <span className="mx-2 md:mx-4">|</span>
          Cybercrime
          <span className="mx-2 md:mx-4">|</span>
          Police decision making 
          <span className="mx-2 md:mx-4">|</span>*/}
              <b>Final deadline for paper submission</b>: April 8, 2024; Open Applied Sessions available for all Conference delegates
        </MarqueeText>
      </MarqueeContainer>
      <Time />
      <div className="mt-8 container mx-auto px-4">
        <h2 className="text-2xl font-bold mb-4 text-center text-gray-700">Important Dates</h2>
        <table className="table-auto w-full">
          <tbody>
            <tr>
              <td className="border px-4 py-2 font-bold">Submissions Open</td>
              <td className="border px-4 py-2">January 15, 2024</td>
            </tr>
            <tr>
              <td className="border px-4 py-2 font-bold">Submission Deadline</td>
              <td className="border px-4 py-2">April 8, 2024</td>
            </tr>
            <tr>
              <td className="border px-4 py-2 font-bold">Decision Notifications</td>
              <td className="border px-4 py-2">Ongoing, Mar-Apr, 2024</td>
            </tr>
            <tr>
              <td className="border px-4 py-2 font-bold">Scheduling Information</td>
              <td className="border px-4 py-2">May, 2024</td>
            </tr>
          </tbody>
        </table>
      </div>
     {/*  <div className="mb-8 my-14">
        <p className="text-xl sm:text-3xl font-bold text-gray-900 mb-4">
          Special IAIP Conference Issues in the{' '}
          <a
            href="https://onlinelibrary.wiley.com/journal/15444767"
            className="text-blue-500 underline"
            target="_blank"
            rel="noopener noreferrer"
          >
            Journal of Investigative Psychology and Offender Profiling
          </a>
        </p>
        <div className="mb-8 my-14">
          <p className="text-lg text-gray-700">
            IAIP kindly invites the authors of the presentations to submit a manuscript for publication in one of the special issues in the{' '}
            <a
              href="https://onlinelibrary.wiley.com/journal/15444767"
              target="_blank"
              rel="noreferrer"
              className="text-blue-500 underline">
              Journal of Investigative Psychology and Offender Profiling.
            </a>
          </p>
        </div>
        <div className="mb-6">
          <p className="text-lg text-gray-700 mb-4">
            These special issues will include articles covering two important themes from the conference:
          </p>
          <ul className="list-disc pl-6 mb-4">
            <li className="text-lg text-gray-700 mb-2 font-bold">The Science and Practice of Investigative Psychology</li>
            <li className="text-lg text-gray-700 mb-2 font-bold">New Challenges in the field of Violent Extremism and Radicalization</li>
          </ul>
        </div>
        <p className="text-lg text-gray-700 mb-6">
          We will be accepting submissions using any systematic methodology, including case studies, qualitative explorations, and quantitative analyses of any appropriate level of sophistication in the field of psychology and the related behavioral and social sciences that may be relevant to criminal and civil investigations and their legal consequences.
        </p>
        <p className="italic text-lg text-gray-700 mb-4">
          Authors should kindly note that submission implies that the content has not been published or submitted for publication elsewhere except as a brief abstract in the proceedings of IAIP Conference 2024.
        </p>
        <p>
          Please find the author guidelines{' '}
          <a href="https://onlinelibrary.wiley.com/page/journal/15444767/homepage/forauthors.html" target="_blank" rel="noopener noreferrer" className="text-blue-600 underline">
            here.
          </a>
        </p>
      </div> */}
    </div>
  );

};

export default CallforPapers;