import React from 'react';
import { SlActionUndo } from "react-icons/sl";
import { useNavigate } from 'react-router-dom';
import Francisca from '../biography/images/Francisca Fariña.JPG'

export default function ByographyProfessorFranciscaFariña() {
    const navigate = useNavigate();

    const handleGoBack = () => {
        navigate(-1);
    };

    return (
        <>
            <div className="flex items-center justify-start mb-8 md:mb-10 mt-20 md:mt-20 p-6 md:p-8">
                <button
                    className="flex items-center justify-center text-white bg-gradient-to-r from-blue-500 to-purple-500 px-4 py-2 focus:outline-none border  rounded-md transition duration-300"
                    onClick={handleGoBack}
                >
                    <SlActionUndo className="text-xl" />
                    <span className="ml-2">Back</span>
                </button>
            </div>
            <div className="mb-8 md:mb-10 p-6 md:p-8 bg-white rounded-md shadow-md max-w-2xl mx-auto">
                <h2 className="text-3xl md:text-4xl text-center font-semibold mb-4 md:mb-6 text-slate-900">
                Professor Francisca Fariña
                </h2>
                <div className="flex flex-col items-center space-y-4">
                    <img
                        src={Francisca}
                        alt="Francisca"
                        className="rounded-full h-40 md:h-60 w-40 md:w-60 mb-2 md:mb-4 object-cover"
                    />
                    <p className="text-sm md:text-base text-gray-800 text-justify">

                        Francisca Fariña is Full Professor of Child and Adolescent Legal Psychology at the University of Vigo (Spain), Chief of the Coexistence Unit of the University of Vigo, and serves as forensic psychologist (knowledge transfer) in the Forensic psychology Institute of the University of Santiago de Compostela. She is Associate Editor of the European Journal of Psychology Applied to Legal Context (Rank 1 of 154 in the Law Category of the JCR).
                        His research topics include forensic assessment, testimony, legal decision making, criminal behaviour prediction, credibility assessment, and any other issue related with forensic psychology. She has published 76 scientific papers indexed in the Web of Science on these topics, has edited 41 books and 154 book chapters, and presented more than 450 conferences and communications to congresses. She has an H index of 49 in Google Scholar and more than 7500 cites, an H index of 28 in the Web of Science, and more than 535,000 downloads in Researchgate. She has supervised 16 doctoral theses. Her research was granted by Spanish Committee for the Assessment of the Research Action (CNEAI) with six periods of six years (sexenios). From her research activity several procedures and protocols used as reference by forensic psychologists in Spain and Latin-American countries have been stemmed (e.g., a forensic interview for child witness, the creation and validation of forensic interview for disabled witness, the creation and validation of the clinical forensic interview, the creation and validation of a protocol to assess the testimony, the Global Evaluation System [GES]). All the forensic techniques derived from her research (scientific evidence-based) were validated in judicial setting in sentences of the Supreme Court. By his research activity, professor Fariña has been awarded by the Spanish Ministry of Science and Education with the Spanish National Award in Educational Research in 2003 edition by her research on the prediction of the criminal behavior. Finally, it is underscored that from her research has been stemmed significant knowledge for the justice application, the intervention with families under separation or divorce. By the knowledge transfer, the Spanish Home Office and the Spanish Prison Office stemmed him the Silver Medal to the Penitentiary Mention.

                    </p>
                </div>
            </div>
        </>
    );
}
