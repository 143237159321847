import React from 'react';
import { SlActionUndo } from "react-icons/sl";
import { useNavigate } from 'react-router-dom';
import Donna from './images/Donna Youngs.jpg';

export default function BiographyDonnaYoungs() {
  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate(-1);
  };

  return (
    <>
       <div className="flex items-center justify-start mb-8 md:mb-10 mt-20 md:mt-20 p-6 md:p-8">
        <button
          className="flex items-center justify-center text-white bg-gradient-to-r from-blue-500 to-purple-500 px-4 py-2 focus:outline-none border  rounded-md transition duration-300"
          onClick={handleGoBack}
        >
          <SlActionUndo  className="text-xl" />
          <span className="ml-2">Back</span>
        </button>
      </div>
      <div className="mb-8 md:mb-10 p-6 md:p-8 bg-white rounded-md shadow-md max-w-2xl mx-auto">
        <h2 className="text-3xl md:text-4xl text-center font-semibold mb-4 md:mb-6 text-slate-900">
        Professor Donna Youngs
        </h2>
        <div className="flex flex-col items-center space-y-4">
          <img
            src={Donna}
            alt="Donna"
            className="rounded-full h-40 md:h-60 w-40 md:w-60 mb-2 md:mb-4 object-cover"
          />
          <p className=" text-justify text-sm md:text-base text-gray-800 ">
          Professor Donna Youngs is an Investigative Psychologist based at Northumbria University in the UK. She trained under Professor David Canter and coauthored the first textbook of the discipline with him. Donna specialises on the modelling of modus operandi, criminal specialisation and offender narratives. Her current projects focus on criminal history patterns, terrorist radicalisation and measuring offender narratives. She has provided expert input to approximately 200 television programmes on psychological profiling.
          </p>
        </div>
      </div>
    </>
  );
}
