import React from 'react';
import LogoUSC from '../images/USC.jpg';
import BASAFELAB from '../images/BSAFE LAB.png';
import styled, { keyframes } from 'styled-components';
import Video from '../video/Santiago de Compostela.mp4';

const marqueeAnimation = keyframes`
  0% {
    transform: translateX(100%);
    opacity: 0;
  }
  5% {
    opacity: 1;
  }
  95% {
    opacity: 1;
  }
  100% {
    transform: translateX(-100%);
    opacity: 0;
  }
`;

const MarqueeContainer = styled.div`
  overflow: hidden;
  white-space: nowrap;
  width: 100%;
  box-sizing: border-box;
  padding: 2px;
  border: 1px solid #ccc;
  border-radius: 5px;
  position: relative;
`;

const MarqueeText = styled.p`
  display: inline-block;
  margin: 0;
  animation: ${marqueeAnimation} 15s linear infinite;
`;


const Home = () => {

  return (
    <div className="container mx-auto mt-16 px-4">
      <div className="flex flex-col md:flex-row justify-between items-center">
        <div className="w-full md:w-2/3 mb-8 md:mb-0">
          <h1 className=" text-lg md:text-4xl font-bold mb-4 my-8 text-slate-900 leading-tight">
            INVESTIGATIVE PSYCHOLOGY: GLOBAL DEVELOPMENTS <span className="text-primary-500">IN BEHAVIOURAL SCIENCE CONTRIBUTIONS TO POLICING</span>
          </h1>

          <div className="mb-6 text-gray-600 ">
            <h3 className='p-1'>The 19th Conference of The International Academy of Investigative Psychology</h3>
            <h3 className='p-1'> 12 -14 June 2024</h3>
            <h3 className='p-1'>Santiago de Compostela</h3>
          </div>
          <div className="flex flex-wrap items-center justify-center lg:space-x-4">
            <div className="relative w-full h-96 lg:w-11/12 overflow-hidden rounded-md shadow-lg">
              <video
                className="w-full h-full object-cover"
                src={Video}
                title="Santiago de Compostela"
                allow="autoplay; encrypted-media"
                allowFullScreen
                controls={false}
                muted
                playsInline
                loop
                autoPlay
              ></video>
            </div>
          </div>
        </div>
        <div className=" flex flex-col w-full md:w-1/3">
          <div className="mb-6 my-32 p-4 bg-white rounded-md shadow-md">
            <h2 className="text-xl md:text-4xl  text-center  font-semibold mb-4 text-slate-900">Supporting Organisations</h2>
            <div className="flex justify-center items-center space-x-4">
              <a href="http://www.bsafe-lab.org/" target="_blank" rel="noopener noreferrer">
                <img src={BASAFELAB} alt="BSAFE LAB" className="w-40 md:w-40 md:h-20 object-contain rounded-lg" />
              </a>
              <a href="https://www.usc.gal/en" target="_blank" rel="noopener noreferrer">
                <img src={LogoUSC} alt="Universidade de Santiago de Compostela" className="w-40 md:w-40 md:h-20 object-contain rounded-lg" />
              </a>
            </div>
          </div>
          <MarqueeContainer>
            <MarqueeText className=" text-slate-900 mt-8 p-3">
              {/* Call for Papers (Submission deadline: 31 March 2024)
              <span className="mx-2 md:mx-4">|</span>
              Early bird registration (Until 31st March 2024)
              <span className="mx-2 md:mx-4">|</span>
              Confirmed Keynote Speakers
              <span className="mx-2 md:mx-4">|</span>
              Workshops
              <span className="mx-2 md:mx-4">|</span>
              Conference themes
              <span className="mx-2 md:mx-4">|</span> */}
              <span className='mx-2 md:mx-4'>Check out the <a href="/PDF/IAIP Leaflet (1) (2).pdf" className='text-blue-600 font-extrabold hover:underline  hover:text-blue-400 cursor-pointer' target='blanck'>conference program</a></span>
              <span className='mx-2 md:mx-4'>See the Conference Book of Abstracts <a href="/PDF/IAIP Book of Abstracts_v4.pdf" target='blanck' className='text-blue-600 font-extrabold hover:underline hover:text-blue-400 cursor-pointer'>more informations</a></span>
            </MarqueeText>
          </MarqueeContainer>
        </div>
      </div>
      <div className="flex flex-col md:flex-row mb-6 my-8 p-4 bg-white rounded-md shadow-md">
        <div className="w-full md:w-1/2">
          <h1 id="keynote-speakers" className="text-xl md:text-4xl font-semibold mb-4 text-slate-900">
            Confirmed Keynote Speakers
          </h1>
          <ul className="list-disc pl-4 mb-6">
            <li className="text-gray-600">
              Dr Joanne Hands – President of the Middle East Psychological Association (MEPA)
            </li>
            <li className="text-gray-600">
              Professor David Canter – Emeritus Professor of Psychology, University of Liverpool
            </li>
            <li className="text-gray-600">
              Professor David Walsh – Professor in Criminal Investigation, De Montfort University
            </li>
            <li className="text-gray-600">
              Professor Maria Ioannou – Professor of Investigative and Forensic Psychology, University of Huddersfield
            </li>
          {/*   <li className="text-gray-600">
              Professor Antonio Andrés-Pueyo - Catedrático de Psicología en la Universidad de Barcelona
            </li> */}
          </ul>
        </div>
        <div className="w-full md:w-1/2">
          <h1 id="open-applied-sessions" className="text-xl md:text-4xl font-semibold mb-4 text-slate-900">
            Open Applied Sessions
          </h1>
          <ul className="list-disc pl-4 mb-6">
            <li className="text-gray-600">
              Professor Jodi Quas
            </li>
            <li className="text-gray-600">
              Professor Ramón Arce
            </li>
            <li className="text-gray-600">
              Secretaría de Estado de Seguridad
            </li>
          </ul>
        </div>
      </div>


    </div>
  );
};

export default Home;